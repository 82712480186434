import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";

export class IconHelper {


  public static parseIcon(icon: string, solid: boolean = false, light: boolean = false): IconData {
    const data = new IconData(icon);
    data.solid = solid;
    data.light = light;
    return data;
  }


  public static iconDescriptionFromContactType(contactType: string, solid: boolean = false, light: boolean = false): string {

    let icon: string = "asterisk";

    if (!contactType) {
      icon = "asterisk";
    } else if (contactType === Constants.ContactType.Directory) {
      icon = "user";
    } else if (contactType === Constants.ContactType.ApiKey) {
      icon = "key";
    } else if (contactType === Constants.ContactType.Group) {
      icon = "users";
    } else if (contactType === Constants.ContactType.Customer) {
      icon = "address-card";
    } else if (contactType === Constants.ContactType.Prospect) {
      icon = "funnel-dollar";
    } else if (contactType === Constants.ContactType.Marketing) {
      icon = "bullseye-arrow";
    } else if (contactType === Constants.ContactType.Agent) {
      icon = "comment-dollar";
    } else if (contactType === Constants.ContactType.ServiceProvider) {
      icon = "industry-alt";
    } else if (contactType === Constants.ContactType.Vendor) {
      icon = "cube";
    } else if (contactType === Constants.ContactType.Warehouse) {
      icon = "warehouse-alt";
    } else if (contactType === Constants.ContactType.RetailLocation) {
      icon = "store-alt";
    } else if (contactType === Constants.ContactType.Location) {
      icon = "building";
    } else if (contactType === Constants.ContactType.TaxAuthority) {
      icon = "money-bill-alt";
    } else if (contactType === Constants.ContactType.ActivityContact) {
      icon = "user-tag";
    } else if (contactType === Constants.ContactType.CollectionAgency) {
      icon = "money-check-alt";
    } else if (contactType === Constants.ContactType.System) {
      icon = "cog";
    } else if (contactType === Constants.ContactType.Contact) {
      icon = "user-tie";
    } else if (contactType === Constants.ContactType.Any) {
      icon = "asterisk";
    } else {
      icon = "asterisk";
    }

    // May be light or solid (can't be both)
    if (light) {
      icon += " (light)";
    } else if (solid) {
      icon += " (solid)";
    }

    return icon;

  }


  public static iconDescriptionFromContactScope(contactScope: string, solid: boolean = false, light: boolean = false): string {

    let icon: string = "user-plus";

    if (!contactScope) {
      icon = "user-plus";
    } else if (contactScope === Constants.ContactScope.Normal || Helper.equals(contactScope, `Scope:${Constants.ContactScope.Normal}`, true)) {
      icon = "user";
    } else if (contactScope === Constants.ContactScope.Administrator || Helper.equals(contactScope, `Scope:${Constants.ContactScope.Administrator}`, true)) {
      icon = "user-tie";
      light = false;
      solid = true;
    } else if (contactScope === Constants.ContactScope.Limited || Helper.equals(contactScope, `Scope:${Constants.ContactScope.Limited}`, true)) {
      icon = "user-lock";
    } else if (contactScope === Constants.ContactScope.Terminated || Helper.equals(contactScope, `Scope:${Constants.ContactScope.Terminated}`, true)) {
      icon = "user-slash";
    } else {
      icon = "user-plus";
    }

    // May be light or solid (can't be both)
    if (light) {
      icon += " (light)";
    } else if (solid) {
      icon += " (solid)";
    }

    return icon;

  }


  public static iconDescriptionFromBrowserType(solid: boolean = false, light: boolean = false): string {

    //Helper.isBrowserFirefox()
    //let browser: string = Helper.browserVersion();
    let icon = "browser";
    if (Helper.isBrowserFirefox()) {
      icon = "firefox (brand)";
    } else if (Helper.isBrowserChrome()) {
      icon = "chrome (brand)";
    } else if (Helper.isBrowserEdge()) {
      icon = "edge (brand)";
    } else if (Helper.isBrowserEdgeLegacy()) {
      icon = "edge-legacy (brand)";
    } else if (Helper.isBrowserIE()) {
      icon = "internet-explorer (brand)";
    } else if (Helper.isBrowserSafari()) {
      icon = "safari (brand)";
    }

    // May be light or solid (can't be both)
    if (light) {
      icon += " (light)";
    } else if (solid) {
      icon += " (solid)";
    }

    return icon;

  }


  public static iconDataFromContactType(contactType: string, solid: boolean = false, light: boolean = false, includeTitle: boolean = false, extraClasses: string = "", extraStyles: string = ""): IconData {
    const iconDescription = IconHelper.iconDescriptionFromContactType(contactType, solid, light);
    const iconData = IconHelper.parseIcon(iconDescription, solid, light);
    if (includeTitle) {
      iconData.title = Constants.ContactType.description(contactType);
    }
    iconData.extraClasses = extraClasses;
    iconData.extraStyles = extraStyles;
    return iconData;
  }


  public static iconDataFromContactScope(contactScope: string, solid: boolean = false, light: boolean = false, includeTitle: boolean = false, extraClasses: string = "", extraStyles: string = ""): IconData {
    const iconDescription = IconHelper.iconDescriptionFromContactScope(contactScope, solid, light);
    const iconData = IconHelper.parseIcon(iconDescription, solid, light);
    if (includeTitle) {
      iconData.title = Constants.ContactScope.description(contactScope);
    }
    iconData.extraClasses = extraClasses;
    iconData.extraStyles = extraStyles;
    return iconData;
  }


  public static iconDataFromIconDescription(iconDescription: string, solid: boolean = false, light: boolean = false, title: string = "", extraClasses: string = "", extraStyles: string = ""): IconData {
    const iconData = IconHelper.parseIcon(iconDescription, solid, light);
    iconData.title = title;
    iconData.extraClasses = extraClasses;
    iconData.extraStyles = extraStyles;
    return iconData;
  }

  public static iconTextOverIcon(text: string, iconDescription: string, title: string = "", extraClasses: string = "", extraStyles: string = "", textExtraClasses: string = "", textExtraStyles: string = ""): IconData {

    // Object holds info about the stack
    const iconData = new IconData();
    iconData.title = title;
    iconData.extraClasses = extraClasses;
    iconData.extraStyles = extraStyles;

    // Bottom icon is the image
    iconData.stackedBottom = IconHelper.iconDataFromIconDescription(iconDescription);
    iconData.stackedBottom.size = "2x";

    // Top icon is the text
    iconData.stackedTop = new IconData();
    iconData.stackedTop.text = text;
    iconData.stackedTop.extraClasses = textExtraClasses;
    iconData.stackedTop.extraStyles = textExtraStyles;

    return iconData;

  }

  public static iconTextOverCalendar(text: string, iconDescription: string = "calendar", title: string = "", extraClasses: string = "", extraStyles: string = ""): IconData {
    return IconHelper.iconTextOverIcon(text, iconDescription, title, extraClasses, extraStyles, "", "margin-top:0.3em;");
  }

  public static iconTextOverFile(text: string, iconDescription: string = "file", title: string = "", extraClasses: string = "", extraStyles: string = ""): IconData {
    return IconHelper.iconTextOverIcon(text, iconDescription, title, extraClasses, extraStyles, "", "margin-top:0.2em;");
  }

  public static iconTextOverSearch(text: string, iconDescription: string = "search", title: string = "", extraClasses: string = "", extraStyles: string = ""): IconData {
    return IconHelper.iconTextOverIcon(text, iconDescription, title, extraClasses, extraStyles, "", "font-size:1.2em;margin-top:-0.3em; margin-left:-0.35em;");
  }

}

export class IconData {

  /**
  Our internal icon description which may have additional information posted as suffix (e.g. "user (light)")
  */
  public iconDescription: string = "";

  public prefix: string = "";
  public iconName: string = "";

  public solid: boolean = false;
  public light: boolean = false;
  public brand: boolean = false;
  public duotone: boolean = false;

  public size: string = ""; // xs, sm, lg, 2x-10x, 15x, 20x, 30x, font-size px, em, %
  public color: string = "";

  /**
  If using text instead of an icon this is the text to use.  This is only used in a stacked icon scenario
  where text is appearing over an icon (e.g. a date on a calendar icon, etc.)
  */
  public text: string = "";

  /*
  Animation to use for the icon.
  Options include:
  spin, pulse, wrench, ring, horizontal, vertical, flash, bounce, float, pulse,
  shake, tada,passing, passing-reverse, burst, falling, a-spin, a-pulse
  PLUS optional attribute suffixes (hover), (fast), (slow)
  */
  public animationDescription: string = "";
  public flip: "none" | "horizontal" | "vertical" = "none";
  public pull: "none" | "left" | "right" = "none";
  public rotate: "0" | "90" | "180" | "270" = "0";
  public fixedWidth: boolean = false;
  public border: boolean = false;
  public inverse: boolean = false;

  public animation: string = "";
  public advancedAnimation: string = "";
  public advancedAnimationHover: boolean = false;
  public advancedAnimationFast: boolean = false;
  public advancedAnimationSlow: boolean = false;
  public fontSize: string = "";
  public iconSize: string = "";

  /**
  If stacking icons this is the icon that goes on the bottom of the stack.
  */
  public stackedBottom: IconData = null;
  /**
  If stacking icons this is the icon that goes on the top of the stack.
  */
  public stackedTop: IconData = null;
  /**
  Stacked icons have underlying icon 2x normal size.  When true that is then cut by 50% to make stacked icon the same size as other icons.
  */
  public normalizeStackedSize: boolean = true;

  /**
   * Stacked group class structure may differ based on icon size.
   */
  public stackedGroupClasses: string = "fa-stack-group";


  protected validate(): void {

    // If icon description has " over " separator that indicates a stacked icon situation so parse and
    // create the top and bottom parts of the stack.
    if (Helper.contains(this.iconDescription, " over ", false)) {
      let topSize: string = "0p25x";
      let bottomSize: string = "1x";
      if (this.size && Helper.endsWith(this.size, "x") && this.size !== "1x") {
        let sizeNumber: number = parseInt(this.size.replace("x", ""), 10);
        // Decrease size by 1.5 ... 3x = 1p5x
        topSize = `${sizeNumber - 2}p5x`;
        bottomSize = this.size;
      }
      if (bottomSize === "1x") {
        this.stackedGroupClasses = "fa-stack-group-1x";
      } else {
        this.stackedGroupClasses = "fa-stack-group";
      }
      //console.error(this.size, topSize, bottomSize, this.stackedGroupClasses);
      const top: string = Helper.left(this.iconDescription, this.iconDescription.indexOf(" over ")).replace(" over ", "");
      const bottom: string = this.iconDescription.substring(this.iconDescription.indexOf(" over ")).replace(" over ", "");
      this.stackedTop = new IconData(top, this.animationDescription);
      this.stackedTop.size = topSize;
      this.stackedTop.flip = this.flip;
      this.stackedTop.rotate = this.rotate;
      this.stackedTop.pull = this.pull;
      this.stackedBottom = new IconData(bottom, "");
      this.stackedBottom.size = bottomSize;
      this.stackedBottom.flip = this.flip;
      this.stackedBottom.rotate = this.rotate;
      this.stackedBottom.pull = this.pull;
      //console.error(this.iconDescription, this.stackedTop, this.stackedBottom);
      return;
    }

    // Parse (solid), (light), or (brand) from name leaving name as is and storing sanitized value in icon
    if (Helper.endsWith(this.iconDescription, "(solid)", true)) {
      this.solid = true;
      this.iconName = this.iconDescription.replace("(solid)", "").trim();
    } else if (Helper.endsWith(this.iconDescription, "(light)", true)) {
      this.light = true;
      this.iconName = this.iconDescription.replace("(light)", "").trim();
    } else if (Helper.endsWith(this.iconDescription, "(duotone)", true)) {
      this.duotone = true;
      this.iconName = this.iconDescription.replace("(duotone)", "").trim();
    } else if (Helper.endsWith(this.iconDescription, "(brand)", true)) {
      this.brand = true;
      this.iconName = this.iconDescription.replace("(brand)", "").trim();
    } else {
      this.iconName = this.iconDescription;
    }

    // We cannot be brand and solid or light; likewise, we cannot be both solid and light and duotone, etc.
    if (this.brand) {
      this.solid = false;
      this.light = false;
      this.duotone = false;
    } else if (this.solid) {
      this.light = false;
      this.duotone = false;
    } else if (this.light) {
      this.duotone = false;
    }

    // Parse (inverse) from name leaving name as is and storing sanitized value in icon
    if (Helper.endsWith(this.iconDescription, "(inverse)", true)) {
      this.inverse = true;
      this.iconName = this.iconDescription.replace("(inverse)", "").trim();
    }

    // Figure out what prefix we want to use
    this.prefix = "far";
    if (this.brand) {
      this.prefix = "fab";
    } else if (this.solid) {
      this.prefix = "fas";
    } else if (this.light) {
      this.prefix = "fal";
    } else if (this.duotone) {
      this.prefix = "fad";
    }

    // Parse out some advanced animation suffix values
    if (Helper.contains(this.animationDescription, "(hover)", true)) {
      this.advancedAnimationHover = true;
      this.animationDescription = this.animationDescription.replace("(hover)", "").trim();
    }
    if (Helper.contains(this.animationDescription, "(fast)", true)) {
      this.advancedAnimationFast = true;
      this.animationDescription = this.animationDescription.replace("(fast)", "").trim();
    }
    if (Helper.contains(this.animationDescription, "(slow)", true)) {
      this.advancedAnimationSlow = true;
      this.animationDescription = this.animationDescription.replace("(slow)", "").trim();
    }

    // Figure out the animation or advanced animation to use
    if (Helper.equals(this.animationDescription, "spin", true)) {
      this.animation = "spin";
    } else if (Helper.equals(this.animationDescription, "pulse", true)) {
      this.animation = "pulse";
    } else if (Helper.equals(this.animationDescription, "wrench", true)) {
      this.animation = "";
      this.advancedAnimation = "wrench";
    } else if (Helper.equals(this.animationDescription, "ring", true)) {
      this.animation = "";
      this.advancedAnimation = "ring";
    } else if (Helper.equals(this.animationDescription, "horizontal", true)) {
      this.animation = "";
      this.advancedAnimation = "horizontal";
    } else if (Helper.equals(this.animationDescription, "vertical", true)) {
      this.animation = "";
      this.advancedAnimation = "vertical";
    } else if (Helper.equals(this.animationDescription, "flash", true)) {
      this.animation = "";
      this.advancedAnimation = "flash";
    } else if (Helper.equals(this.animationDescription, "bounce", true)) {
      this.animation = "";
      this.advancedAnimation = "bounce";
    } else if (Helper.equals(this.animationDescription, "float", true)) {
      this.animation = "";
      this.advancedAnimation = "float";
    } else if (Helper.equals(this.animationDescription, "shake", true)) {
      this.animation = "";
      this.advancedAnimation = "shake";
    } else if (Helper.equals(this.animationDescription, "tada", true)) {
      this.animation = "";
      this.advancedAnimation = "tada";
    } else if (Helper.equals(this.animationDescription, "passing", true)) {
      this.animation = "";
      this.advancedAnimation = "passing";
    } else if (Helper.equals(this.animationDescription, "passing-reverse", true)) {
      this.animation = "";
      this.advancedAnimation = "passing-reverse";
    } else if (Helper.equals(this.animationDescription, "burst", true)) {
      this.animation = "";
      this.advancedAnimation = "burst";
    } else if (Helper.equals(this.animationDescription, "falling", true)) {
      this.animation = "";
      this.advancedAnimation = "falling";
    } else if (Helper.equals(this.animationDescription, "a-spin", true)) {
      this.animation = "";
      this.advancedAnimation = "spin";
    } else if (Helper.equals(this.animationDescription, "a-pulse", true)) {
      this.animation = "";
      this.advancedAnimation = "pulse";
    } else if (this.animationDescription) {
      Log.errorMessage(`Unexpected icon animation setting ${this.animationDescription}.  Expected values include: spin, pulse, wrench, ring, horizontal, vertical, flash, bounce, float, shake, tada, passing, passing-reverse, burst, falling, a-spin, a-pulse with possible attribute suffixes (hover), (fast), (slow).`);
      this.animation = "";
      this.advancedAnimation = "";
    }

    if (!Helper.equals(this.flip, "none", true) &&
      !Helper.equals(this.flip, "horizontal", true) &&
      !Helper.equals(this.flip, "vertical", true)) {
      Log.errorMessage(`Unexpected icon flip setting ${this.flip}.  Expected values include: none, horizontal, vertical.`);
      this.flip = "none";
    }

    if (this.rotate != "0" && this.rotate != "90" && this.rotate != "180" && this.rotate != "270") {
      Log.errorMessage(`Unexpected icon rotate setting ${this.rotate}.  Expected values include: 0, 90, 180, 270.`);
      this.rotate = "0";
    }

    if (!Helper.equals(this.pull, "none", true) &&
      !Helper.equals(this.pull, "left", true) &&
      !Helper.equals(this.pull, "right", true)) {
      Log.errorMessage(`Unexpected icon pull setting ${this.pull}.  Expected values include: none, left, right.`);
      this.pull = "none";
    }

    // Parse size if we were given one
    if (Helper.startsWith(this.size, "fa-")) {
      // Remove any fa- prefix
      this.size = this.size.replace("fa-", "");
    }
    if (Helper.endsWith(this.size, "xs", true)) {
      this.iconSize = "xs";
    } else if (Helper.endsWith(this.size, "sm", true)) {
      this.iconSize = "sm";
    } else if (Helper.endsWith(this.size, "lg", true)) {
      this.iconSize = "lg";
    } else if (Helper.endsWith(this.size, "x", true) && !Helper.endsWith(this.size, "px", true)) {
      // Ends with x and not px then we're 2x, 3x, 4x, etc. which if font awesome size helper so post as-is
      this.iconSize = this.size;
    } else if (Helper.endsWith(this.size, "px", true) || Helper.endsWith(this.size, "em", true) || Helper.endsWith(this.size, "%", true)) {
      // We were given a font size not an icon size
      this.fontSize = this.size;
    } else if (this.size) {
      Log.errorMessage(`Unexpected icon size setting ${this.size}.  Expected values include: xs, sm, lg, 2x-10x, or font size in px, em, %.`);
    }

    // If our icon description starts with 'text:' then this is probably stacked text
    if (Helper.startsWith(this.iconDescription, "text:")) {
      this.text = this.iconDescription.replace("text:", "");
      this.iconName = "";
      if (this.stackedTop && this.stackedTop.size === "0p25x") {
        // For top text with 1x bottom icon we need a larger font size than we have for a top icon
        this.stackedTop.size = "0p5x";
      }
    }

  }


  /**
  Calculated classes based on various property settings
  */
  public get calculatedClasses(): string {

    this.validate();

    // Now build out the font classes we want
    let classes: string = `${this.prefix} fa-${this.iconName}`;
    if (this.iconSize) {
      classes += ` fa-${this.iconSize}`;
    }
    if (this.fixedWidth) {
      classes += " fa-fw";
    }
    if (this.animation) {
      classes += ` fa-${this.animation}`;
    } else if (this.advancedAnimation && this.advancedAnimationHover) {
      classes += ` faa-${this.advancedAnimation} animated-hover`;
    } else if (this.advancedAnimation) {
      classes += ` faa-${this.advancedAnimation} animated`;
    }
    if (this.advancedAnimationFast) {
      classes += " faa-fast";
    } else if (this.advancedAnimationSlow) {
      classes += " faa-slow";
    }
    if (this.flip && this.flip === "horizontal") {
      classes += " fa-flip-horizontal";
    } else if (this.flip && this.flip === "vertical") {
      classes += " fa-flip-vertical";
    }
    if (this.rotate && this.rotate !== "0") {
      classes += ` fa-rotate-${this.rotate}`;
    }
    if (this.border) {
      classes += " fa-border";
    }
    if (this.inverse) {
      classes += " fa-inverse";
    }
    if (this.pull && this.pull === "left") {
      classes += " fa-pull-left";
    } else if (this.pull && this.pull === "right") {
      classes += " fa-pull-right";
    }

    return classes;

  }

  /**
  Any extra classes that should be appended in the html.  This can be used to specify margins, padding, colors, etc.
  */
  public extraClasses: string = "";

  /**
  Calculated styles based on various property settings
  */
  public get calculatedStyles(): string {

    this.validate();

    // Build any styles we need
    let styles: string = "";
    if (this.fontSize) {
      styles += `font-size:${this.fontSize};`;
    }
    if (this.color) {
      let color = this.color.trim();
      if (!Helper.startsWith(color, "#", false)) {
        if (Helper.equals(color, "primary", true)) {
          color = "#007bff";
        } else if (Helper.equals(color, "secondary", true)) {
          color = "#6c757d";
        } else if (Helper.equals(color, "success", true)) {
          color = "#28a745";
        } else if (Helper.equals(color, "danger", true)) {
          color = "#dc3545";
        } else if (Helper.equals(color, "warning", true)) {
          color = "#ffc107";
        } else if (Helper.equals(color, "info", true)) {
          color = "#17a2b8";
        } else if (Helper.equals(color, "light", true)) {
          color = "#f8f9fa";
        } else if (Helper.equals(color, "dark", true)) {
          color = "#343a40";
        } else if (Helper.equals(color, "default", true)) {
          color = "buttonface";
        }
      }
      //console.error(this.color, color);
      styles += `color:${color};`;
    }

    return styles;

  }

  /**
  Any styles that should be included in the html.  This can be used to specify margins, padding, colors, etc.
  */
  public extraStyles: string = "";

  /**
  If title should be included in html mark up for hover label purposes that goes here.
  */
  public title: string = "";

  public get iconHtml(): string {

    let html = "";

    // If we have text this is text being centered over a stacked icon
    if (this.text) {
      html = `<strong class="fa-stack-1x ${this.extraClasses}" style="${this.calculatedStyles}${this.extraStyles}">${this.text}</strong>`;
      return html;
    }

    html += `<i `;
    if (this.title) {
      html += `title='${this.title}' `;
    }
    html += `class='${this.calculatedClasses} ${this.extraClasses}' `;
    const styles = this.calculatedStyles + this.extraStyles;
    if (styles) {
      html += `style='${styles}' `;
    }
    html += `></i>`;
    return html;

  }

  public get html(): string {

    // If not stacked then the icon html is the html we want
    if (!this.stackedBottom || !this.stackedTop) {
      return this.iconHtml;
    }

    const iconHtml1 = this.stackedBottom.iconHtml;
    const iconHtml2 = this.stackedTop.iconHtml;

    let html = `<span title="${this.title}" class="fa-stack ${this.extraClasses}" styles="${this.extraStyles}">` + "\n";
    if (this.normalizeStackedSize) {
      html = `<span title="${this.title}" class="fa-stack ${this.extraClasses}" style="${this.extraStyles}font-size:0.525em;">` + "\n";
    }
    html += iconHtml1 + "\n";
    html += iconHtml2 + "\n";
    html += "</span>";

    return html;

  }

  public constructor(iconDescription: string = "", animationDescription: string = "") {
    this.iconDescription = iconDescription;
    this.animationDescription = animationDescription;
    this.validate();
  }


}
