import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, AfterViewInit, Directive } from '@angular/core';
import { TableModule, Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ApiProperties, Query, ApiOperationType, ApiCall, IApiResponseWrapper, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { SafeStyle, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TableBaseClass } from './table-base-class';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { QueryService } from 'projects/core-lib/src/lib/services/query.service';
import { TableService } from 'projects/core-lib/src/lib/services/table.service';
import { UxService } from '../services/ux.service';

@Directive()
export abstract class TableNativeBaseClass extends TableBaseClass implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected queryService: QueryService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef) {
    super(apiService, appService, queryService, uxService, sanitizer, cdr, elementRef);
  }

  // ngOnInit() {
  //   super.ngOnInit();
  // }
  // ngOnChanges(changes: SimpleChanges) {
  //   super.ngOnChanges(changes);
  // }
  // ngAfterViewInit() {
  //   super.ngAfterViewInit();
  // }
  // ngAfterViewChecked() {
  //   super.ngAfterViewChecked();
  // }


  rowDrop($event: CdkDragDrop<any[]>) {

    //console.error("drop", $event);
    // Move element
    const updates: { allItems: any[], changedItems: any[] } = Helper.arrayElementMove(this.data, $event.previousIndex, $event.currentIndex, this.options.orderPropertyName);

    // Submit api updates for any changed items
    const results = this.saveOrderChangesToApi(updates?.changedItems);

    // Emit event so owner knows things changed
    const cargo = {
      previousIndex: $event.previousIndex,
      currentIndex: $event.currentIndex,
      changedItems: updates?.changedItems,
      changedItemsUpdateSuccessCount: results?.filter(x => x.Data.Success)?.length,
      changedItemsUpdateFailedCount: results?.filter(x => !x.Data.Success)?.length
    };
    const payload: EventModel = new EventModel("rowReorder", $event, this.data, new EventElementModel("table", this.options.tableId), cargo);
    this.rowReorder.emit(payload);

  }



  public onRowClick($event, row: any, index: number) {
    let isRightButton = Helper.htmlEventIsRightMouseButton($event);
    this.selectedData = row;
    //console.error("row click", isRightButton, row, index);
    if (isRightButton) {
      this.contextMenuRowIndex = index;
      this.prepareContextMenu(row);
    } else {
      if (this.options.rowSelectedAction) {
        this.options.rowSelectedAction(row, this.selectedData, this.data, {});
      }
    }
  }


  public filterGlobalOnInput($event): void {
    // Save our filter text in case we need to reference it programatically outside of the context of an event handler
    console.error("global input", $event);
    //this.globalFilterText = $event.data;
    //// For lazy loaded tables we filter on change event.  For non-lazy loaded tables we filter on input event.
    //if (!this.lazy) {
    //  this.table.filterGlobal(this.globalFilterText, "contains");
    //}
  }

  public filterGlobalOnChange($event): void {
    // Save our filter text in case we need to reference it programatically outside of the context of an event handler
    console.error("global change", $event);
    //this.globalFilterText = $event.data;
    //// For lazy loaded tables we filter on change event.  For non-lazy loaded tables we filter on input event.
    //if (this.lazy) {
    //  this.table.filterGlobal(this.globalFilterText, "contains");
    //}
  }



}
