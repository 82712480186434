import { Component, OnInit } from '@angular/core';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';
import { MenuItem } from 'primeng/api';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { UxService } from 'projects/common-lib/src/lib/services/ux.service';
import { MenuService } from 'projects/core-lib/src/lib/services/menu.service';

/*
  Note: Unable to properly test the file because of the behavior of AppConfig and how it is declared
*/

@Component({
  selector: 'ib-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent extends BaseComponent implements OnInit {

  public title = "IB NG";
  public menu: MenuItem[] = [];
  public taskStatus: m5.TaskListStatusViewModel = new m5.TaskListStatusViewModel();

  /**
  * Currently authenticated user.
  */
  public get user(): m5sec.AuthenticatedUserViewModel {
    return this.appService.user;
  }

  get userName(): string {
    return this.appService.userOrDefault.ContactName;
  }

  get isPartitionZero(): boolean {
    return (this.appService.userOrDefault.PartitionId === 0);
  }

  get isUserDirectoryUser(): boolean {
    return (this.appService.isUserDirectoryUser);
  }

  public get showHelpMenu(): boolean {
    // We only show the help menu for "app" application type
    if (this.appService.config.type !== "app") {
      return false;
    }
    return this.appService.showHelpMenu;
  }

  public get showSearch(): boolean {
    // We only show the help menu for "app" application type
    if (this.appService.config.type !== "app") {
      return false;
    }
    return this.appService.search.hasGlobalSearchConfigurations;
  }

  public get searchConfigCount(): number {
    return this.appService.search?.globalSearchConfigurations?.length || 0;
  }

  public get searchConfigs(): m5web.SearchConfigurationViewModel[] {
    return this.appService.search?.globalSearchConfigurations || [];
  }


  public helpTopic: m5web.HelpLinkEditViewModel = null;


  public recentCasesTooltip: string = "Recent Cases";


  public partitionNoticeColor: string = "danger";
  public partitionNoticeIcon: string = "";
  public partitionNoticeText: string = "";


  constructor(
    public nav: NavService,
    protected menuService: MenuService,
    protected appService: AppService,
    protected uxService: UxService) {

    super();

    this.title = AppConfig.name;
    this.recentCasesTooltip = `Recent ${this.appService.getLabel("CasePlural", "Cases", false)}`;

  }

  ngOnInit() {
    super.ngOnInit();
    // Listen for menu changes
    this.menuService.primeMenuFeed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(menu => {
      //console.error("nav side got new menu", menu);
      if (menu) {
        this.menu = [...menu];
      }
    });
  }


  ngAfterViewInit() {
    super.ngAfterViewInit();

    // Our title defaults to AppConfig.name but some partition domains may have custom title to use
    if (this.appService.appInfoOrDefault?.Domain?.DisplayName) {
      this.title = Helper.getFirstDefinedString(this.appService.appInfoOrDefault.Domain.DisplayName, AppConfig.name);
    }
    this.appService.tryGetAppInfo().pipe(takeUntil(this.ngUnsubscribe)).subscribe((info: m5core.ApplicationInformationModel) => {
      this.title = Helper.getFirstDefinedString(info?.Domain?.DisplayName, AppConfig.name);
      // With app info loaded we'll now have any labels we want to use
      this.recentCasesTooltip = `Recent ${this.appService.getLabel("CasePlural", "Cases", false)}`;
    });

    this.appService.tryGetUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: m5sec.AuthenticatedUserViewModel) => {
      if (user) {
        // We need to know our contact type and partition for partition notice so wait for our user object to be loaded
        this.setupPartitionNotice();
      }
    });

    this.appService.taskStatusMonitor().pipe(takeUntil(this.ngUnsubscribe)).subscribe((status: m5.TaskListStatusViewModel) => {
      if (status) {
        this.taskStatus = status;
      }
    });

    this.appService.helpLinkMonitor().pipe(takeUntil(this.ngUnsubscribe)).subscribe((helpLink: m5web.HelpLinkEditViewModel) => {
      this.helpTopic = helpLink;
    });

  }


  // ngAfterViewChecked() {
  //   super.ngAfterViewChecked();
  // }

  setupPartitionNotice() {

    this.partitionNoticeColor = "danger";
    this.partitionNoticeIcon = "";
    this.partitionNoticeText = "";

    if (this.appService.config.type !== "app") {
      return;
    }
    if (this.appService.userOrDefault.ParentContactType !== Constants.ContactType.Directory) {
      return;
    }

    if (this.appService.config.showPartitionZeroWarning && this.appService.userOrDefault.PartitionId === 0) {
      this.partitionNoticeColor = "danger";
      if (Helper.isMobile()) {
        this.partitionNoticeIcon = "";
        this.partitionNoticeText = "P0";
      } else {
        this.partitionNoticeIcon = "exclamation-triangle";
        this.partitionNoticeText = "Partition Zero";
      }
      return;
    }

    if (this.appService.config.partitionNotices) {
      const match = this.appService.config.partitionNotices.find(x => x.partitionId === this.appService.userOrDefault.PartitionId);
      if (match) {
        this.partitionNoticeColor = match.color || "danger";
        if (Helper.isMobile()) {
          this.partitionNoticeIcon = match.mobileIcon;
          this.partitionNoticeText = match.mobileText
        } else {
          this.partitionNoticeIcon = match.icon;
          this.partitionNoticeText = match.text;
        }
      }
    }

  }


  makeVisible() {
    // If menu pop-up button gets clicked on we want to scroll to the top to make sure the pop-up menu is visible
    Helper.scrollToTop();
  }

  logout() {
    this.appService.logout();
  }

  bookmarkPage() {
    this.appService.bookmarkPage();
  }


  gotoHomePage() {
    this.appService.redirectToHome();
  }

  setHomePage() {
    const url: string = this.appService.location.path();
    this.appService.preferenceValueSet(Constants.ContactPreference.ApplicationSettings, "PortalHomePage", url);
  }


}
