import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy, AfterViewInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { FormHelper } from 'projects/common-lib/src/lib/form/form-helper';
import { NgForm, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { InputStatusChangeModel } from 'projects/common-lib/src/lib/input/input-status-change-model';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { FormBaseClass } from 'projects/common-lib/src/lib/form/form-base-class';
import { CanComponentDeactivate } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { HandlebarsHelpers } from 'projects/core-lib/src/lib/helpers/handlebars-helpers';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { FormMessageModel, FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';
import { UxService } from '../../services/ux.service';
import { DynamicFormService } from 'projects/core-lib/src/lib/services/dynamic-form.service';

@Component({
  selector: 'ib-form-render',
  templateUrl: './form-render.component.html',
  styleUrls: ['./form-render.component.css'],
  providers: [FormStatusService]
})
export class FormRenderComponent extends FormBaseClass<any> implements OnInit, OnChanges, OnDestroy, AfterViewInit, CanComponentDeactivate {

  @Input() formModel: m5web.FormEditViewModel;
  @Input() formIsReadOnly: boolean = false;
  @Input() loading: boolean = false;
  @Input() working: boolean = false;
  /**
  If another component is hosting the form this input attribute allows the host to submit an
  error to be included with the form errors.  This will most often come from a failed save event.
  */
  @Input() error: FormMessageModel;
  /**
  If another component is hosting the form this input attribute allows the host to signal when a
  save is successful by incrementing the count.  The form will then decide how to act.
  */
  @Input() saveCount: number = 0;
  /**
  If another component is hosting the form this input attribute allows the host to signal when a
  cancel has been triggered by incrementing the count.  The form will then decide how to act.
  */
  @Input() cancelCount: number = 0;

  @Input() groupScope: "A" | "E" = "E"; // L = list scope which is N/A here and B = both which applies to add and edit so we just need to know add (A) or edit (E)

  @Input() contextResourceType: string = "";
  @Input() contextResourceId: number = 0;
  @Input() contextResourceId2: string = "";
  @Input() context: any = null;

  @Output() addSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() addClose: EventEmitter<EventModel> = new EventEmitter();
  @Output() editSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() editClose: EventEmitter<EventModel> = new EventEmitter();

  /**
  G = General
  S = Success
  C = Cancel
  There is a group type A but that indicates All and isn't a setting used here since it's applicable everywhere.
  */
  groupType: "G" | "S" | "C" = "G";

  public dataChangeCount: number = 0;

  public controlStatus: { [index: string]: InputStatusChangeModel } = {};
  //public formIsPristine: boolean = true;
  //public formIsValid: boolean = true;
  //public formErrors: string[] = [];


  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected formService: FormStatusService,
    protected dynamicFormService: DynamicFormService,
    protected _changeDetectionRef: ChangeDetectorRef) {
    super(appService, uxService, formService, false, null);
  }

  ngOnInit() {
    super.ngOnInit();
  }


  ngOnChanges(changes: SimpleChanges) {

    super.ngOnChanges(changes);

    if (changes.formModel && this.formModel) {
      this.formId = Helper.replaceAll(this.formModel.Description, " ", "");
      this.formDescription = this.formModel.Description;
      // Now refresh our form status
      this.refreshFormStatus();
    }

    if (changes.saveCount && changes.saveCount.currentValue) {
      // Data save was triggered by hosting component and this is how it let's us know to fire the form defined save action
      this.formPristine();
      this.setControlStatusPristine();
      // TODO after save touching a previously dirty control in an embedded custom form marks the form as dirty!!!
      //if (this.form) {
      //  this.form.resetForm();
      //  this.form.reset();
      //  if (this.form.form) {
      //    this.form.form.reset();
      //  }
      //}
      if (this.mode === "add") {
        this.addSaveAction();
      }
    }

    if (changes.cancelCount && changes.cancelCount.currentValue) {
      // Form cancel was triggered by hosting component and this is how it let's us know to fire the form defined cancel action
      if (this.mode === "add") {
        this.addCancelAction();
      }
    }

    if (changes.error && changes.error.currentValue) {
      // Error was triggered by hosting component and this is how it let's us know
      //console.error("form has error and should handle it on its own");
    }

    if (changes.data && this.data) {
      // Now refresh our form status
      this.refreshFormStatus();
    }

    if (changes.formModel || changes.data || changes.groupType || changes.groupScope) {
      // one of the inputs that impacts visibility has changed so we need to reevaluate visibility
      if (this.formModel) {
        this.dynamicFormService.evaluateGroupVisibility(this.formModel.Groups, this.data, this.groupType, this.groupScope, false);
      }
    }

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // Force another change detection in order to fix a changed expression error
    this._changeDetectionRef.detectChanges();
  }


  addSaveAction() {

    //console.error(`form saved now fire save action ${this.formModel.AddSaveAction}`);

    if (Helper.equals(this.formModel.AddSaveAction, "G", true)) {
      this.groupType = "S";
    }

  }

  addCancelAction() {

    //console.error(`form cancelled now fire cancel action ${this.formModel.AddCancelAction}`);

    if (Helper.equals(this.formModel.AddCancelAction, "G", true)) {
      this.groupType = "C";
    }

  }


  onChange($event) {
    //console.error("form change event", $event);
    this.dataChangeCount++;
    //console.error(this.dataChangeCount);
  }


  onStatusChange($event) {

    //console.error("form onStatusChange", $event);

    // Post control status
    this.controlStatus[$event.event.name] = $event.event;

    // Now refresh our form status
    this.refreshFormStatus();

    //// Now emit our form status event so the owner can know if we're good to go or not.
    //// Note that owner may have multiple forms that owner is tying together as these
    //// can be parts of various forms being pulled together.
    //let model: FormStatusModel = new FormStatusModel();
    //model.isPristine = this.formIsPristine;
    //model.isValid = this.formIsValid;
    //model.errors = this.formErrors;
    let payload: EventModel = new EventModel("form", this.formStatus, this.formStatus, new EventElementModel("form", (this.formModel.FormId || 0).toString(), this.formModel.Description));
    this.status.emit(payload);

  }


  setControlStatusPristine(): void {
    for (const key in this.controlStatus) {
      const status: InputStatusChangeModel = this.controlStatus[key];
      if (status) {
        status.dirty = false;
        status.pristine = true;
      }
    }
  }


  public refreshFormStatus(): void {

    //let model = new FormStatusModel();

    //console.error("refreshFormStatus()");

    // This will clear any non-custom errors
    this.formService.formStatusCheck();

    // Now loop through our control status objects to see what our form status is
    for (const key in this.controlStatus) {
      const status: InputStatusChangeModel = this.controlStatus[key];
      if (status) {
        // If one control is invalid or dirty then the form is as well
        if (status.dirty) {
          //console.error(`${key} is dirty`);
          this.formDirty();
          //model.isPristine = false;
        }
        // Now step through errors to report what specifically is going on.  Only set valid false when we have an actual error.
        if (status.errors) {
          this.formService.addControlErrors(status.name, status.errors);
        }
      }
    }
    //console.error("status", this.controlStatus);

    this.formStatus = this.formService.status;
    //console.error(this.formStatus);

    return;

  }



  onAddSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    // $event bubbles up from our form control render component and is already in expected format
    this.addSave.emit($event);
  }

  onAddClose($event) {
    // $event bubbles up from our form control render component and is already in expected format
    this.addClose.emit($event);
  }

  onEditSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    // $event bubbles up from our form control render component and is already in expected format
    this.editSave.emit($event);
  }

  onEditClose($event) {
    // $event bubbles up from our form control render component and is already in expected format
    this.editClose.emit($event);
  }


  // /**
  // * Determine if the group should be displayed or not.
  // */
  // showGroup(group: m5web.FormControlGroupEditViewModel): boolean {

  //   // Make sure our group type is the expected group type or "All"
  //   if (!Helper.equals(group.GroupType, this.groupType, true) && !Helper.equals(group.GroupType, "A", true)) {
  //     return false;
  //   }

  //   // Make sure our group scope is the expected group scope or "Both"
  //   if (!Helper.equals(group.GroupScope, this.groupScope, true) && !Helper.equals(group.GroupScope, "B", true)) {
  //     return false;
  //   }

  //   // If we have a display when macro expression let's evaluate it to figure out if we're going to display the control or not
  //   if (group.DisplayWhenExpression && Helper.contains(group.DisplayWhenExpression, "{{")) {
  //     const result = HandlebarsHelpers.handlebarsTemplateResolve(group.DisplayWhenExpression, this.data, `FormControlGroupId-${group.FormControlGroupId}-DisplayWhenExpression`);
  //     if (result && !Helper.equals(result, "false", true)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }

  //   // Nobody said not to show it so we're showing it
  //   return true;

  // }




  groupTrackByFn(index: number, group: m5web.FormControlGroupEditViewModel) {
    if (group && group.FormControlGroupId) {
      return group.FormControlGroupId;
    }
    // Return negative index to avoid possible collision with FormControlGroupId
    return (-1 * index);
  }


}
