import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { TableModule, Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ApiProperties, Query, ApiOperationType, ApiCall, IApiResponseWrapper, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { SafeStyle, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TableBaseClass } from '../table-base-class';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { QueryService } from 'projects/core-lib/src/lib/services/query.service';
import { TableNativeBaseClass } from '../table-native-base-class';
import { TableService } from 'projects/core-lib/src/lib/services/table.service';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'ib-native-table',
  templateUrl: './native-table.component.html',
  styleUrls: ['./native-table.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class NativeTableComponent extends TableNativeBaseClass implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  @Input() allowDragOutsideTable: boolean = false;

  /**
   * By default we only allow rows to be dragged up or down (along the y axis) but
   * if allowDragOutsideTable gets set to true we remove that to allow rows to be
   * dragged outside of the table.
   * @type y or x is expected by cdkDragLockAxis but it works with "" to ignore the lock
   * although type checking fails on that so we type this as y, x, "", or any.  Obviously
   * we could just type as any but the others are listed here for documentation purposes
   * as expected values.
   */
  dragLockAxis: "y" | "x" | "" | any = "y";

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected queryService: QueryService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef) {
    super(apiService, appService, queryService, uxService, sanitizer, cdr, elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.allowDragOutsideTable) {
      this.dragLockAxis = "";
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.allowDragOutsideTable) {
      if (this.allowDragOutsideTable) {
        this.dragLockAxis = "";
      } else {
        this.dragLockAxis = "y";
      }
    }
  }
  // ngAfterViewInit() {
  //   super.ngAfterViewInit();
  // }
  // ngAfterViewChecked() {
  //   super.ngAfterViewChecked();
  // }


}
