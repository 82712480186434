import { Input, ViewChild, OnInit, OnChanges, OnDestroy, AfterViewInit, SimpleChanges, Output, EventEmitter, HostListener, isDevMode, Directive } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { NgForm } from '@angular/forms';
import { Subscription, Observable, from, of, Subject } from 'rxjs';
import { EventModel, EventElementModel, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { CanComponentDeactivate } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { ModalService } from 'projects/common-lib/src/lib/modal/modal.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';
import { CanDoWhat } from 'projects/core-lib/src/lib/models/security';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';
import { BaseComponent } from '../../../../core-lib/src/lib/helpers/base-component';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { AlertItemType, AlertItem } from '../alert/alert-manager';
import { UxService } from '../services/ux.service';

@Directive()
export abstract class FormBaseClass<T> extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, CanComponentDeactivate {

  /**
  We usually have data of some form attached to our form.  Use any for generic type if
  we have a form that doesn't have data or doesn't want to use this property.  Note that
  we support 2-way data binding here ... [(data)]="myDataObject" via watching form value
  changes and then firing event.
  */
  @Input() data: T = null; @Output() public dataChange: EventEmitter<T> = new EventEmitter();

  /**
   * Permission area which when set will be used along with user object to determine the
   * permissions the user has for this permission area.
   */
  @Input() permissionArea: string = "";

  /**
  Form reset count is a way for us to monitor changes on an input attribute and use that
  change to trigger setting a form back to pristine.  This is done in scenarios where
  a form's contents are saved but we don't want to navigate away from the form but instead
  allow continued editing.
  */
  @Input() formResetCount: number = 0;

  /**
  Form dirty count is a way for us to monitor changes on an input attribute and use that
  change to trigger setting a form to dirty.  This is done in scenarios where
  a form's contents are edited from outside the component.
  */
  @Input() formDirtyCount: number = 0;

  ///**
  //If this component is embedded within another component this is an optional input attribute
  //for the parent form status.  This can be important if our component wants to make decisions
  //about actions like reloading, navigating away, etc. and may not want to do that if the
  //parent form is dirty.  When present this is treated as the formStatus object to be used
  //for this embedded form.
  //*/
  //@Input() parentFormStatus: FormStatusModel;

  /**
  Our form status is not often passed in but in some scenarios where users can switch between
  views like wizard vs. form view the status is shared and supplied by the hosting component.
  Note that it gets updated when changes are detected and emitted to any listeners
  when that overall status changes and is also passed into button components in the view.
  */
  @Input() formStatus: FormStatusModel = new FormStatusModel();

  @Input() disabled: boolean = false;
  @Input() mode: "add" | "edit" | "view" | "list" = "edit"; // note "list" is rarely used except in custom components as that's usually part of our editor not form
  @Input() host: "page" | "modal" | "component" = "page";

  /**
  When the status of our form changes we emit a status event.
  */
  @Output() status: EventEmitter<EventModel> = new EventEmitter();

  /**
  When the form has a value change this event gets fired with data.
  */
  @Output() change: EventEmitter<EventModel> = new EventEmitter();

  /**
  By convention our forms have <form #f="ngForm"> form tag which then provides the class
  access to the form for validation purposes.
  */
  @ViewChild('f') protected form: NgForm;
  /**
  The formId property can be set in classes that inherit from this class.  It is included
  in the element object of any events emitted regarding the form.
  */
  protected formId: string = "";
  /**
  The formDescription property can be set in classes that inherit from this class.  It is included
  in the element object of any events emitted regarding the form.
  */
  protected formDescription: string = "";
  /**
  The formCargo property can be set in classes that inherit from this class.  It is included
  in any events emitted regarding the form.
  */
  protected formCargo: any = {};

  /**
   * If formStatus is passed in dirty note that here so we can flag our form as dirty.
   */
  protected formStartedDirty: boolean = false;

  /**
  When true our deactivate guard will warn us about any unsaved changes.
  */
  protected warnAboutUnsavedChanges: boolean = true;

  protected contextMenuSelectedObject: any = null;
  protected contactMenuSelectedIndex: number = -1;

  /**
   * Provide context if loading eye candy is desired.
   */
  public loading: boolean = false;

  /**
   * When a read-only object is detected an alert message is provided to explain why it's read only.  If this
   * button is populated then it will be added to the alert so the user can make a copy of the object for editing.
   */
  protected readOnlyObjectCopyButton: ButtonItem = null;

  /**
   * Permissions based on current user and permission area input attribute.
   */
  private _permissions: CanDoWhat = null;
  public get permissions(): CanDoWhat {
    if (!this._permissions) {
      this._permissions = this.appService.security.parsePermissions(this.appService.user, this.permissionArea);
    }
    return this._permissions;
  }

  /**
   * Currently authenticated user.
   */
  public get user(): m5sec.AuthenticatedUserViewModel {
    return this.appService.user;
  }

  /**
   * Returns true if the currently authenticated user has sys admin permissions.
   */
  public get hasSysAdminPermissions(): boolean {
    return this.appService.security.hasSysAdminPermission(this.appService.user);
  }

  public get appInfo(): m5core.ApplicationInformationModel {
    return this.appService.appInfoOrDefault;
  }

  public get isBrandReportCompiler(): boolean {
    return this.appService.isBrandReportCompiler;
  }


  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected formService: FormStatusService,
    protected requiresValidatedUser: boolean,
    protected requiresContactType: string) {

    super();

    // Clear any existing context sensitive help links.  Subclass will set the help appropriate for that component.
    this.appService.helpLink = null;

    if (this.appService.config.debug && isDevMode()) {
      //Log.debugMessage("If running dev deploy you can access component for debug by clicking on the element in dev tools and typing 'ng.probe($0)' or 'ng.probe($0).componentInstance' in the console.");
      Log.debugMessage("You can access a component instance by selecting it in the elements tab and then executing this from the console: > ng.getComponent($0)");
    }

  }


  ngOnInit() {

    // Subclasses should always call super.ng*() for these life cycle hooks.
    super.ngOnInit();

    // Validate user if called for
    if (this.requiresValidatedUser && !this.appService.isLoggedIn()) {
      this.appService.redirectToLogin(true);
    }
    if (this.requiresValidatedUser || this.requiresContactType) {
      // This will redirect to login within tryGetUser() if needed
      this.appService.tryGetUser(true).pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: m5sec.AuthenticatedUserViewModel) => {
        if (user && user.ParentContactType && this.requiresContactType) {
          if (!Helper.equals(user.ParentContactType, this.requiresContactType, true)) {
            // This user isn't valid for this view so redirect them home
            Log.errorMessage(`The authenticated user is contact type '${user.ParentContactType}' not contact type '${this.requiresContactType}' as requested by '${Helper.getFirstDefinedString(this.formDescription, this.formId)}' so redirecting to home.`);
            this.appService.redirectToHome();
          }
        }
      });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.mode && changes.host) {
      this.validateInputParameters();
    }
    if (changes.formResetCount) {
      // When editing a form we may have saved the form and want to continue working.
      // On save we need to reset the form to pristine and the easiest way to
      // do that is by incrementing a counter that is bound to this formResetCount input attribute.
      //console.error(`form id ${this.formId} form reset count ${this.formResetCount}`);
      this.formPristine();
      this.formService.markAsPristine();
    }
    if (changes.formDirtyCount && this.formDirtyCount) {
      // When form data is modified from outside of the component this value can be incremented
      // which we detect here and set or form as dirty.
      this.formDirty();
      this.formService.markAsDirty();
    }
    if (changes.data) {
      this.dataChanged(changes.data.firstChange);
    }
    if (changes.permissionArea) {
      this._permissions = null;
    }
    //if (changes.formStatus && this.formStatus && !this.formStatus.isPristine) {
    //  // If we were given a form status and it was dirty then mark our form as dirty
    //  // since our form status will update and will override what was given to us
    //  this.formStartedDirty = true;
    //  console.error('form dirty');
    //  this.formDirty();
    //}
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.appService.alertManager.close("read-only-object");
  }

  ngAfterViewInit() {
    if (this.form) {
      this.formService.addForm(this.form.form);
      //// Subscribe to form changes so we can update the status of the form
      //this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(form => {
      //  this.formStatusCheck();
      //});
      // Subscribe to form value changes so we can tell parent who may be listening
      this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(form => {
        // Fire 2 way data binding
        this.dataChange.next(this.data);
        // Fire separate change event if watched
        const payload: EventModel = new EventModel("change", form, this.data, new EventElementModel("form", this.formId, this.formDescription), this.formCargo);
        this.change.emit(payload);
      });
    }
    // Subscribe to form status changes so we can emit change
    this.formService.statusChanges().pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.formStatus = status;
      //console.error("new status", status);
      // Emit status event to listeners
      const payload: EventModel = new EventModel("status", this.formStatus, this.formStatus, new EventElementModel("form", this.formId, this.formDescription), this.formCargo);
      this.status.emit(payload);
    });
    // We want at least one initial status check since the form may have required values, etc.
    // but we wouldn't otherwise know about them until something changed in the form itself
    // based on our subscription above.
    //setTimeout(() => this.formStatusCheck(true), 100);
    //setTimeout(() => this.formStatusCheck(), 100);
    //if ((this.formStatus && !this.formStatus.isPristine) || this.formStartedDirty) {
    //  this.formDirty();
    //}

    // If we have a default tab set defined and the url has a tab to select in the hash portion then select that tab
    // Note that subclasses should set the default tab set before calling super.ngAfterViewInit() or this won't do anything
    this.tabSelectFromUrl();

  }

  /**
   * Validate input parameters to make sure they are supported values.
   */
  protected validateInputParameters() {

    this.mode = (this.mode || "" as any).toLowerCase();
    if (this.mode !== "add" && this.mode !== "edit" && this.mode !== "view") {
      Log.errorMessage(`Data editor form mode ${this.mode} is not recognized.  Accepted values are "add", "edit", and "view".  Defaulting to "edit".`);
      this.mode = "edit";
    }

    this.host = (this.host || "" as any).toLowerCase();
    if (this.host !== "page" && this.host !== "modal" && this.host !== "component") {
      Log.errorMessage(`Data editor form host ${this.host} is not recognized.  Accepted values are "page", "modal", and "component".  Defaulting to "page".`);
      this.host = "page";
    }

  }

  /**
   * If ngOnChange detects that the data has changed this method gets fired.  This can be
   * used by our subclasses to enable them to react to that change by overriding this method.
   * This is often done for things like updating calculated values, etc.
   * @param firstChange
   */
  protected dataChanged(firstChange: boolean) {
    if (this.data && (<any>this.data).MetaData && (<any>this.data).MetaData.ReadOnly) {
      console.warn("This object has MetaData.ReadOnly set to true so disabling the form.");
      const alert = new AlertItem();
      alert.type = AlertItemType.Warning;
      alert.message = `This is a read-only ${Helper.getFirstDefinedString(this.formDescription, "object")}.  To edit these values please make a copy first.`;
      alert.id = "read-only-object";
      if (this.readOnlyObjectCopyButton) {
        alert.buttons.push(this.readOnlyObjectCopyButton);
      }
      //this.appService.alertManager.addAlertMessage(AlertItemType.Warning, `This is a read-only ${Helper.getFirstDefinedString(this.formDescription, "object")}.  To edit these values please make a copy first.`);
      this.appService.alertManager.add(alert);
      this.disabled = true;
    }
  }

  protected setPermissionArea(permissionArea: string) {
    this.permissionArea = permissionArea;
    this._permissions = null;
  }

  public workareaHeight(componentHeightUsed: number = 100): number {
    return this.appService.workareaHeight(componentHeightUsed);
  }

  /**
   * If context menu is attached to a container that holds multiple objects but
   * also has whitespace we could attach this event to mousedown event on that
   * container element so if click is on whitespace the context menu object and
   * index are cleared.
   * @param $event
   */
  onObjectContainerClickClearContextMenu($event: any) {
    this.contextMenuSelectedObject = null;
    this.contactMenuSelectedIndex = -1;
    return;
  }

  /**
   * If context menu exists on a container that holds multiple objects via ngFor
   * or other mechanism and we need to know which object the context menu clicked
   * on the mousedown event of the objects so the contextMenuSelectedObject and
   * contactMenuSelectedIndex properties will indicate what was selected by the
   * context menu.
   * @param $event
   * @param obj
   * @param index
   */
  onObjectClickPrepareContextMenu($event: any, obj: any, index: number) {
    if (!obj) {
      this.contextMenuSelectedObject = null;
      this.contactMenuSelectedIndex = -1;
      return;
    }
    if (Helper.htmlEventIsRightMouseButton($event)) {
      this.contextMenuSelectedObject = obj;
      this.contactMenuSelectedIndex = index;
      try {
        // Don't let event propagate up to onObjectContainerClickClearContextMenu()
        $event.stopPropagation();
        $event.preventDefault();
      } catch (err) {
        //Log.errorMessage(err);
      }
    } else {
      this.contextMenuSelectedObject = null;
      this.contactMenuSelectedIndex = -1;
    }
  }

  ///**
  // * If our form has child forms that share data and need to share form status we
  // * have their status event call this method so we can keep track of their status
  // * and merge it into our form status.
  // * @param $event
  // * @param childName
  // */
  //onChildFormStatusChange($event: EventModel, childName: string) {
  //  //console.error("child form status change", partial, $event);

  //  // Keep track of form status on any children so we can use it in roll up of our own form status
  //  this.childFormStatus.add(childName, $event.data);

  //  // If our child form is dirty then our parent form must be dirty as this is where
  //  // things like save button get enabled, warnings on discarding dirty form happens, etc.
  //  if (!$event.data.isPristine) {
  //    this.formDirty();
  //  }

  //  // Update form status now that we have info on child form
  //  //this.formStatusCheck(true);
  //  //this.formStatusCheck();

  //}

  ///**
  // * Returns true if our form is dirty based on NgForm or if that isn't available based on FormStatus.
  // */
  //protected isFormDirty(): boolean {
  //  if (this.form && this.form.form) {
  //    return this.form.form.dirty;
  //  } else if (this.parentFormStatus) {
  //    // If we have a parent form we assume it's listening and acting on our form status changes
  //    // as it's child and, therefore, our form status is represented in the parent form status.
  //    return !this.parentFormStatus.isPristine;
  //  } else if (this.formStatus) {
  //    return !this.formStatus.isPristine;
  //  } else {
  //    // Just guessing here
  //    Log.errorMessage("Asked if form is dirty but no form, parentFormStatus, or formStatus object exists so guessing false!");
  //    return false;
  //  }
  //}

  /**
   * Sets our form as dirty.  Note this is public as it's part of an interface shared with a few forms and passed
   * around to action buttons, etc. that need to be able to handle setting the form dirty.
   */
  public formDirty() {
    if (this.form && this.form.form) {
      this.form.form.markAsDirty();
      this.formService.formStatusCheck();
      //setTimeout(() => {
      //  this.form.form.markAsDirty();
      //}, 0);
    } else if (this.formStatus) {
      this.formStatus.isPristine = false;
    }
    //this.formStatusCheck(true);
    //this.formStatusCheck();
  }

  /**
   * Sets our form as pristine.
   */
  protected formPristine() {
    //console.error(`form id ${this.formId} set pristine`);
    if (this.form && this.form.form) {
      this.form.form.markAsPristine();
      this.form.form.markAsUntouched();
      this.form.form.updateValueAndValidity();
      this.formService.formStatusCheck();
      // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false'.
      // https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
      // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
      //setTimeout(() => {
      //  this.form.form.markAsPristine();
      //}, 0);
      // try these?
      //this.<your_form>.form.markAsPristine();
      //this.<your_form>.form.markAsUntouched();
      //this.<your_form>.form.updateValueAndValidity();
    }
    if (this.formStatus) {
      this.formStatus.isPristine = true;
    }
    //this.formStatusCheck(true);
    //this.formStatusCheck();
  }

  ///**
  // * This method checks our form status based on available NgForm and/or child FormStatusModel
  // * objects.  If the status has changed or if force parameter is true then the newly created
  // * status object is saved to our form status member and the status is emitted to any listeners.
  // * @param force
  // */
  //protected formStatusCheck(force: boolean = false) {

  //  // Get a status model based on the form looks like now
  //  let currentStatus: FormStatusModel = this.formService.status;

  //  // If the current status represents a change then update our form status and emit status event to any listeners
  //  if (force || !FormStatusModel.equals(this.formStatus, currentStatus)) {
  //    // Status is changed
  //    this.formStatus = currentStatus;
  //    // Emit status event to listeners
  //    let payload: EventModel = new EventModel("status", this.formStatus, this.formStatus, new EventElementModel("form", this.formId, this.formDescription), this.formCargo);
  //    this.status.emit(payload);
  //  }

  //}


  /**
   * Helper method to get desired label.  This allows customization of things like
   * "Case", "ExternalCaseId", etc. to be more desirable text for user scenario
   * where "Case" might be referred to as "Issue", "File", etc.
   * Wraps AppService.getLabel() so can be called from html views.
   * @param label
   * @param defaultValue
   * @param translate
   */
  getLabel(label: string, defaultValue: string = "", translate: boolean = true) {
    return this.appService.getLabel(label, defaultValue, translate);
  }

  hasModule(module: string, logWarningIfModuleMissing: boolean = false): boolean {
    return this.appService.hasModule(module, logWarningIfModuleMissing);
  }

  hasPermission(accessArea: string, permission: string): boolean {
    return this.appService.security.hasPermission(this.user, accessArea, permission);
  }


  /**
   * Helper method to get properties from kvp into class members.  This is often used to grab route data or
   * route query string parameters and assign them to class members without needing repeated code for simple
   * assignments.  More complex assignments are handled manually.
   * @param kvp
   * @param propertyNames
   */
  protected updateMembersFromNamedProperties(kvp: { [propertyName: string]: any }, ...propertyNames: string[]) {

    if (!kvp || !propertyNames || propertyNames.length === 0) {
      return;
    }

    propertyNames.forEach(propertyName => {
      try {
        let value = kvp[propertyName];
        if (value !== undefined) {
          this[propertyName] = value;
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    });

  }


  hasHistory(): boolean {
    return (window.history.length > 0);
  }




  // Basic tab support with options to push/pop state so we can land back on the tab we left when we navigated away

  /**
   * The default tab set used in tab restoration when user hits back button.  If the default tab set is not defined then all of this
   * functionality gets ignored.  If this functionality is desired then subclasses need to do the following:
   * 1. Make sure the html mark up for their tab set has tab change event set up and tab set component tagged for view child support.  e.g.
   *    <ngb-tabset type="{{isMobile ? 'pills' : 'tabs'}}" [destroyOnHide]="false" (navChange)="onNavChange($event)" #tabset="ngbTabset">
   * 2. Get a view child for their tab set e.g. @ViewChild('tabset', { static: true }) protected tabSet: NgbTabset;
   * 3. In their ngAfterViewInit() implementation assign that tab set to the default tab set before calling super.ngAfterViewInit().  e.g.
   *    ngAfterViewInit() {
   *       // Set default tab set before calling super.ngAfterViewInit() so it can restore selected tab from url hash (if any)
   *       this.defaultTabSet = this.tabSet;
   *       super.ngAfterViewInit();
   *    }
   * 4. In their onNavChange() implementation call super.onNavChange($event) so can push the selected tab into the history state.  e.g.
   *    onNavChange($event) {
   *       super.onNavChange($event);
   *    }
   */
  protected defaultTabSet: NgbNav = null;

  /**
   * Event that fires when a tab is selected.  Subclasses should call this base implementation which takes care of
   * pushing tab state into the browser history when a default tab set has been defined.
   * @param $event
   */
  onNavChange($event) {
    if (this.defaultTabSet && $event && $event.nextId) {
      this.tabPushState($event.nextId);
    }
  }

  /**
   * Selects tab based on hash portion of location.  This is called from
   * popstate listener and ngAfterViewInit.
   * @param location
   */
  tabSelectFromUrl(location: any = null) {
    if (!this.defaultTabSet) {
      // No tab set defined so we don't know what to do
      return;
    }
    if (!location) {
      location = window.location;
    }
    const hash: string = location.hash;
    if (hash && Helper.startsWith(hash, "#tab-", true)) {
      const id: string = hash.substring(5);
      this.defaultTabSet.select(id);
    }
  }

  /**
   * Pushes location with hash marker for tab id into history state so when someone hits
   * back on browser we can programatically restore the tab we were on before provides a
   * default tab set has been assigned.  If no default tab set is defined we don't do
   * anything since we won't know what to restore.
   * @param tabId
   */
  tabPushState(tabId: string) {
    if (!this.defaultTabSet || !tabId) {
      return;
    }
    history.pushState(null, null, `${window.location.origin}${window.location.pathname}#tab-${tabId}`);
  }

  /**
   * @HostListener allows us to intercept history.popState so we can see if there is a url
   * hash that represents a tab we should programatically select.
   */
  @HostListener('window:popstate', ['$event'])
  onPopState($event) {
    this.tabSelectFromUrl(Helper.tryGetValue($event, x => x.target.location, null, null));
  }












  /**
   * If user navigates away and the form is dirty and we want to warn about it then present
   * a prompt asking the user to confirm they intend to discard changes.
   * NOTE:
   * Forms that are referenced by editors will have the editor base class canDeactivate()
   * method fire but we also have the method here for forms that are stand alone components
   * not using our default editor behavior.
   */
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    //console.error("form pristine", this.formStatus.isPristine, "warn", this.warnAboutUnsavedChanges);

    // Allow synchronous navigation ('true') if the form we are watching is not dirty or if we don't care
    if (this.formStatus.isPristine || !this.warnAboutUnsavedChanges) {
      return true;
    }
    //console.error("ready to ask", "pristine", this.formStatus.isPristine, "warn", this.warnAboutUnsavedChanges);

    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    const promise = this.uxService.modal.confirmUnsavedChanges();
    //console.error("form promise", promise);

    // Convert from promise to observable so we can map to boolean
    const observable = from(promise);

    // Map our observable from EventModel to boolean where true means we can deactivate and false means we cannot.
    return observable.pipe(
      map((response) => {
        const model: EventModel = ModalService.fromSimpleModalResultToEventModel(response);
        //console.error("form", model);
        if (model.data) {
          this.warnAboutUnsavedChanges = false;
          //console.error("form warn about unsaved changes", this.warnAboutUnsavedChanges);
        }
        return model.data;
      }),
      catchError((err) => {
        const model: EventModel = ModalService.fromSimpleModalResultToEventModel(err);
        //console.error("form discard error", model);
        return of(model.data);
      }));

  }

  /**
   * @HostListener allows us to also guard against browser refresh, close, etc.  If that happens
   * the user will get a confirmation dialog from the browser asking to verify their choice.
   */
  @HostListener('window:beforeunload')
  canUnload(): boolean {

    // Return true if the form we are watching is not dirty or if we don't care
    if (this.formStatus.isPristine || !this.warnAboutUnsavedChanges) {
      return true;
    }

    // NOTE: Not all browsers support custom warning messages so we may get a generic warning message.
    // See http://stackoverflow.com/a/42207299/7307355.
    const message: string = "WARNING: You have changes that have not been saved.  If you proceed, all of your changes will be lost.  Press Cancel/Stay to go back and save these changes or Ok/Leave/Reload to lose these changes.";

    return confirm(message);

  }


}
