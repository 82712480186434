import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { FilterSelectionData } from '../filter-selection-data';
import { ApiCall, ApiProperties, ApiOperationType, IApiResponseWrapperTyped, Query, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ButtonItem, EventModel, Action, EventElementModel, EventModelTyped } from '../../ux-models';
import { Helper, Log } from '../../../../../core-lib/src/lib/helpers/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { FilterSelectionModalComponent } from '../filter-selection-modal/filter-selection-modal.component';
import { IconHelper } from '../../image/icon/icon-helper';
import { QueryService } from '../../../../../core-lib/src/lib/services/query.service';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'ib-filter-selection-button',
  templateUrl: './filter-selection-button.component.html',
  styleUrls: ['./filter-selection-button.component.css']
})
export class FilterSelectionButtonComponent extends BaseComponent implements OnInit {

  @Input() objectName: string = "";
  @Input() data: FilterSelectionData = {};
  @Input() size: "" | "lg" | "sm" | "xs" | "xxs" = "sm";
  @Input() fullWidth: boolean = false;
  @Input() scope: "ValidForReport" | "ValidForAdHocQuery" | "ValidForDataExport" | "ValidForVisualComponent" | "" = "ValidForVisualComponent";
  @Output() change: EventEmitter<EventModel> = new EventEmitter();

  button: ButtonItem = null;

  /*
   * Option to allow search in button drop down menu when it's a long list
   */
  allowSearch: boolean = false;

  apiProp: ApiProperties;
  apiCall: ApiCall;
  filters: m5.FilterEditViewModel[] = [];

  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected uxService: UxService,
    protected queryService: QueryService,
    protected ngbModalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.button = this.buildActionButton();
    //this.loadFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.data && !this.data) {
      this.data = {};
      this.data.SaveOption = "no";
    }
    if (changes.objectName && this.objectName) {
      this.loadFilters();
    }
  }

  loadFilters(ignoreCache: boolean = false) {
    if (!this.objectName) {
      Log.errorMessage("Cannot load filters for filter selection button due to no object name being assigned.");
      return;
    }
    if (!this.apiProp) {
      this.apiProp = Api.Filter();
    }
    if (!this.apiCall) {
      this.apiCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.List);
      this.apiCall.silent = true;
    }
    this.apiCall.cacheIgnoreOnRead = ignoreCache;
    const query = new Query();
    query.Size = this.Constants.RowsToReturn.All;
    if (this.scope) {
      query.Filter = `ObjectName == "${this.objectName}" && Enabled == 1 && ${this.scope} == 1 && ( Shared == 1 || ContactId == ${this.appService.userOrDefault.ContactId} )`;
    } else {
      query.Filter = `ObjectName == "${this.objectName}" && Enabled == 1 && ( Shared == 1 || ContactId == ${this.appService.userOrDefault.ContactId} )`;
    }
    this.apiService.execute(this.apiCall, query).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel[]>) => {
      if (!result.Data.Success) {
        //this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        Log.errorMessage(result);
      } else {
        this.filters = Helper.arraySort(result.Data.Data, "Description", false, true);
        this.button = this.buildActionButton();
      }
    });
  }


  buildActionButton(): ButtonItem {

    // If we have more than limited number of filters we should include a search input to allow user to type to find their filter
    this.allowSearch = false;
    if (this.filters && this.filters.length > 8) {
      this.allowSearch = true;
    }

    const button = new ButtonItem("Filter", "filter", "outline-secondary");
    button.size = this.size;
    button.menuItemSize = this.size;
    button.scrollHeight = "large";
    button.fullWidth = this.fullWidth;

    // Update method allows us to tweak the button label, icon, etc. based on filter selected or not
    button.update = (action: Action, data: FilterSelectionData, cargo: any) => {
      if (!action) {
        return;
      }
      action.label = "Filter";
      action.icon = "filter";
      action.contextColorIcon = "";
      action.description = "";
      if (data) {
        if (data.Description) {
          action.label = Helper.left(data.Description, 30, true);
          action.icon = "filter (solid)";
          action.contextColorIcon = "danger";
          action.description = "";
          if (data.Description.length > 30) {
            action.description = data.Description;
          }
        } else if (this.queryService.hasFilterConditions(data.FilterConditions)) {
          action.label = "Custom Filter";
          action.icon = "filter (solid)";
          action.contextColorIcon = "danger";
          action.description = "";
        }
      }
      return;
    };
    button.action = (event: EventModel) => {
      this.openFilterBuilder(event)
    };
    button.menuPlacement = "bottom-right";

    // Menu option to clear any selected filter
    const clear: Action = new Action("clear", "Clear", "ban", "default", (event: EventModel) => {
      this.data = {};
      this.data.SaveOption = "no";
      const payload: EventModel = new EventModel("change", event, this.data);
      this.change.emit(payload);
    });
    clear.visible = (data: FilterSelectionData) => {
      //console.error(data);
      if (data && (data.FilterId || data.FilterConditions)) {
        return true;
      } else {
        return false;
      }
    };
    button.options.push(clear);

    // Separator after clear action if a separator is called for
    const clearSeparator: Action = new Action();
    clearSeparator.type = "separator";
    clearSeparator.visible = (data: FilterSelectionData) => {
      //console.error(data);
      if (data && (data.FilterId || data.FilterConditions) && this.filters && this.filters.length > 0) {
        return true;
      } else {
        return false;
      }
    };
    button.options.push(clearSeparator);

    // Add each filter to the list of options.  First personal filters then shared filters.
    if (this.filters && this.filters.length > 0) {
      const group = true;
      let countShared = 0;
      let countPrivate = 0;
      if (group) {
        countShared = this.filters.filter(x => x.Shared).length;
        countPrivate = this.filters.filter(x => !x.Shared).length;
      }
      if (group) {
        if (countPrivate) {
          const header: Action = new Action(`header-private`, "My Filters", "filter (solid)", "default");
          header.contextColorIcon = "primary";
          header.type = "heading";
          button.options.push(header);
          this.filters.filter(x => !x.Shared).forEach((filter) => {
            const pick: Action = new Action(`pick-${filter.FilterId}`, Helper.left(filter.Description, 50, true), "filter (duotone)", "default", (event: EventModel) => {
              this.data = {
                FilterId: event.cargo.action.cargo.FilterId,
                Description: event.cargo.action.cargo.Description,
                Shared: event.cargo.action.cargo.Shared,
                FilterConditions: event.cargo.action.cargo.FilterConditions,
                DataStoreFilterExpression: event.cargo.action.cargo.DataStoreFilterExpression,
                SaveOption: "save"
              };
              const payload: EventModel = new EventModel("change", event, this.data);
              this.change.emit(payload);
            }, filter);
            button.options.push(pick);
          });
        }
        if (countPrivate && countShared) {
          // Separator between private and shared filters
          const sharedSeparator: Action = new Action();
          sharedSeparator.type = "separator";
          button.options.push(sharedSeparator);
        }
        if (countShared) {
          const header: Action = new Action(`header-shared`, "Shared Filters", "filter (solid)", "default");
          header.contextColorIcon = "primary";
          header.type = "heading";
          button.options.push(header);
          this.filters.filter(x => x.Shared).forEach((filter) => {
            const pick: Action = new Action(`pick-${filter.FilterId}`, Helper.left(filter.Description, 50, true), "filter (duotone)", "default", (event: EventModel) => {
              this.data = {
                FilterId: event.cargo.action.cargo.FilterId,
                Description: event.cargo.action.cargo.Description,
                Shared: event.cargo.action.cargo.Shared,
                FilterConditions: event.cargo.action.cargo.FilterConditions,
                DataStoreFilterExpression: event.cargo.action.cargo.DataStoreFilterExpression,
                SaveOption: "save"
              };
              const payload: EventModel = new EventModel("change", event, this.data);
              this.change.emit(payload);
            }, filter);
            button.options.push(pick);
          });
        }
      } else {
        this.filters.forEach((filter) => {
          const pick: Action = new Action(`pick-${filter.FilterId}`, Helper.left(filter.Description, 50, true), "filter (duotone)", "default", (event: EventModel) => {
            this.data = {
              FilterId: event.cargo.action.cargo.FilterId,
              Description: event.cargo.action.cargo.Description,
              Shared: event.cargo.action.cargo.Shared,
              FilterConditions: event.cargo.action.cargo.FilterConditions,
              DataStoreFilterExpression: event.cargo.action.cargo.DataStoreFilterExpression,
              SaveOption: "save"
            };
            const payload: EventModel = new EventModel("change", event, this.data);
            this.change.emit(payload);
          }, filter);
          //if (filter.Shared) {
          //  pick.label = `${Helper.left(filter.Description, 50, true)} (${IconHelper.iconDataFromIconDescription("share-alt").iconHtml})`;
          //}
          button.options.push(pick);
        });
      }
    }

    // Separator before build action if a separator is called for
    const buildSeparator: Action = new Action();
    buildSeparator.type = "separator";
    buildSeparator.visible = (data: FilterSelectionData) => {
      //console.error(data);
      if ((data && (data.FilterId || data.FilterConditions)) || (this.filters && this.filters.length > 0)) {
        return true;
      } else {
        return false;
      }
    };
    button.options.push(buildSeparator);

    // Menu option to build a filter.  Last in the list is ok since button main click action does this as well
    const build: Action = new Action("build", "Build Filter", "filter", "default", (event: EventModel) => {
      this.openFilterBuilder(event)
    });
    button.options.push(build);

    return button;

  }


  openFilterBuilder($event) {
    if (this.data.DataStoreFilterExpression) {
      this.uxService.modal.alertWarning("Filter", "This is an advanced filter that cannot be edited here.  Please use the advanced filter interface to edit this filter.");
      return;
    }
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.title = "Build Filter"
    // Open the modal
    const modalRef = this.ngbModalService.open(FilterSelectionModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.data = Helper.deepCopy(this.data);
    // Default save option is no unless we have a filter id then the default is save
    modalRef.componentInstance.data.SaveOptions = "no";
    if (this.data.FilterId) {
      modalRef.componentInstance.data.SaveOptions = "save";
    }
    modalRef.componentInstance.objectName = this.objectName;

    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModelTyped<FilterSelectionData>) => {
      // User hit ok so value.data is the data object.  Based on save options we will either add or edit.
      this.data = value.data;
      this.data.FilterId = value.data.FilterId;
      this.data.Description = value.data.Description;
      this.data.Shared = value.data.Shared;
      this.data.FilterConditions = value.data.FilterConditions;
      this.data.DataStoreFilterExpression = value.data.DataStoreFilterExpression;
      this.data.SaveOption = value.data.SaveOption;
      if (!this.data.FilterId && !this.queryService.hasFilterConditions(this.data.FilterConditions)) {
        this.data.FilterConditions = null;
      }
      const payload: EventModel = new EventModel("change", event, value.data, new EventElementModel("filter-picker", null));
      this.change.emit(payload);
      // Make a copy of the conditions being saved and purge meta that is only used for ux
      const conditions = Helper.deepCopy(this.data.FilterConditions);
      this.queryService.filterConditionsPurgeMeta(conditions);
      // Now see if we should be saving the filter that was just built
      const save: boolean = (this.data.SaveOption === "save" && !Helper.isEmpty(this.data.FilterId));
      const saveAs: boolean = (!save && Helper.startsWith(this.data.SaveOption, "save", true));
      if (this.data.SaveOption === "delete" && this.data.FilterId) {
        const promise = this.uxService.modal.confirmDelete(`Delete filter ${this.data.Description}?`, null);
        promise.then((answer) => {
          const delCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Delete);
          delCall.silent = true;
          this.apiService.execute(delCall, this.data.FilterId).subscribe((result: IApiResponseWrapper) => {
            if (!result.Data.Success) {
              //this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
              Log.errorMessage(result);
            } else {
              // We just deleted our filter so we need to clear it
              this.data = {};
              this.data.SaveOption = "no";
              const payload: EventModel = new EventModel("change", event, this.data, new EventElementModel("filter-picker", null));
              this.change.emit(payload);
              this.loadFilters(true);
            }
          });
        }, (cancelled) => {
          // No action
          this.data.SaveOption = "save";
        });
      } else if (save) {
        const getCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Get);
        getCall.silent = true;
        this.apiService.execute(getCall, this.data.FilterId).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel>) => {
          if (!result.Data.Success) {
            //this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
            Log.errorMessage(result);
          } else {
            // Assign updated description and filter conditions and save the already existing filter
            result.Data.Data.Description = Helper.getFirstDefinedString(this.data.Description, "Filter");
            result.Data.Data.Shared = this.data.Shared;
            result.Data.Data.ValidForReport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForReport"));
            result.Data.Data.ValidForAdHocQuery = (this.data.ValidFor && this.data.ValidFor.includes("ValidForAdHocQuery"));
            result.Data.Data.ValidForDataExport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForDataExport"));
            result.Data.Data.ValidForVisualComponent = (this.data.ValidFor && this.data.ValidFor.includes("ValidForVisualComponent"));
            result.Data.Data.FilterConditions = conditions;
            const editCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Edit);
            editCall.silent = true;
            this.apiService.execute(editCall, result.Data.Data).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel>) => {
              if (!result.Data.Success) {
                //this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
                Log.errorMessage(result);
              } else {
                const payload: EventModel = new EventModel("change", event, this.data, new EventElementModel("filter-picker", null));
                this.change.emit(payload);
                this.loadFilters(true);
              }
            });
          }
        });
      } else if (saveAs) {
        const model = new m5.FilterEditViewModel();
        model.Description = Helper.getFirstDefinedString(this.data.Description, "Filter");
        model.Enabled = true;
        model.ValidForReport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForReport"));
        model.ValidForAdHocQuery = (this.data.ValidFor && this.data.ValidFor.includes("ValidForAdHocQuery"));
        model.ValidForDataExport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForDataExport"));
        model.ValidForVisualComponent = (this.data.ValidFor && this.data.ValidFor.includes("ValidForVisualComponent"));
        model.Shared = this.data.Shared;
        model.ContactId = this.appService.userOrDefault.ContactId;
        model.ObjectName = this.objectName;
        model.FilterConditions = conditions;
        // Now flip save option from save-as to save since save-as is a user initiated action not a default
        this.data.SaveOption = "save";
        const addCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Add);
        addCall.silent = true;
        this.apiService.execute(addCall, model).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel>) => {
          if (!result.Data.Success) {
            //this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
            Log.errorMessage(result);
          } else {
            this.data.FilterId = result.Data.Data.FilterId;
            const payload: EventModel = new EventModel("change", event, this.data, new EventElementModel("filter-picker", null));
            this.change.emit(payload);
            this.loadFilters(true);
          }
        });
      }
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }



}
