import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5rc from "projects/core-lib/src/lib/models/ngModelsReportCompiler5";
import * as wm5 from "projects/core-lib/src/lib/models/ngWalletModels";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";

export class ApiModuleReportCompiler {



  //#region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModuleReportCompiler).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModuleReportCompiler[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModuleReportCompiler[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModuleReportCompiler.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModuleReportCompiler.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  //#endregion Helper Functions




  //#region Report Compiler APIs

  public static ReportCompilerLibraryGroupConfiguration(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerLibraryGroupConfiguration");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Library Group Configuration";
    api.documentation.objectPrimaryKey = "LibraryGroupId";
    api.documentation.objectDescriptionPropertyNames = ["LibraryGroup"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerLibraryGroupConfiguration;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerLibraryGroupConfigurationModel();
    api.documentation.documentationUrlBase = "/report-compiler/library-groups/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryGroupConfiguration;
    api.pathVariables = "{libraryGroupId}";
    api.pathModelProperties = "LibraryGroupId";
    api.cacheName = "reportCompilerObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.LibraryGroups}`, null, null, true);
    return api;
  };

  public static ReportCompilerLibraryGroupContentMove(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerLibraryGroupContentMove");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Library Group Move Content";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/report-compiler/library-groups/move/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryOrganizationText;
    api.pathVariables = ["{sourceLibraryGroupId}", "{targetLibraryGroupId}"];
    api.pathModelProperties = ["SourceLibraryGroupId", "TargetLibraryGroupId"];
    api.cacheName = "";
    api.cacheLevel = CacheLevel.None;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.LibraryGroups}/{sourceLibraryGroupId}/${m.RouteSegment.Move}/{targetLibraryGroupId}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Library Group Move Content";
    api.endpoints.slice(-1)[0].documentation.menuText = "Library Group Move Content";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to move library content from one library group to a different library group.`;
    return api;
  };

  public static ReportCompilerTemplateTypeConfiguration(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerTemplateTypeConfiguration");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Template Type Configuration";
    api.documentation.objectPrimaryKey = "TemplateTypeId";
    api.documentation.objectDescriptionPropertyNames = ["TemplateType"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerTemplateTypeConfiguration;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerTemplateTypeConfigurationModel();
    api.documentation.documentationUrlBase = "/report-compiler/template-types/";
    api.documentation.securityAccessArea = Constants.AccessArea.TemplateTypeConfiguration;
    api.pathVariables = "{templateTypeId}";
    api.pathModelProperties = "TemplateTypeId";
    api.cacheName = "reportCompilerObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.TemplateTypes}`, null, null, true);
    return api;
  };

  public static ReportCompilerIndustryLibrary(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerIndustryLibrary");
    api.brands = ["ReportCompiler"];
    //api.id = "Asset";
    api.version = version;
    api.documentation.objectDescription = "Industry Library";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.objectDescriptionPropertyNames = ["Title", "FriendlyName", "ExternalAssetId", "NamedIdentifier"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/industry-library/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryIndustryText;
    api.pathVariables = ["{assetId}", "{expand}"];
    api.pathModelProperties = ["AssetId", "Expand"];
    api.cacheName = "assetObjectCache";
    //api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibrary}`, new m5.AssetListViewModel(), Constants.DataModelName.AssetList, false);
    // Note Expand parameter is used to identify the industry library
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibrary}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.AssetListViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.AssetList;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibrary}/{assetId}?expand={expand}`, ApiOperationType.Get));
    return api;
  };

  public static ReportCompilerIndustryLibrarySelection(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerIndustryLibrarySelection");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Industry Library Selection";
    api.documentation.objectPrimaryKey = "AssetSelectionId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetSelection;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetSelectionEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/industry-library/selection/";
    api.documentation.securityAccessArea = Constants.AccessArea.AssetSelection;
    api.pathVariables = ["{assetId}", "{assetSelectionId}", "{expand}"];
    api.pathModelProperties = ["AssetId", "AssetSelectionId", "Expand"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibrary}/{assetId}/${m.RouteSegment.Selections}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibrary}/{assetId}/${m.RouteSegment.Selections}/{assetSelectionId}?expand={expand}`, ApiOperationType.Get));
    return api;
  };

  public static ReportCompilerIndustryLibraryTypeInformation(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerIndustryLibraryTypeInformation");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Industry Library Type Information";
    api.documentation.objectPrimaryKey = "IndustryLibraryTypeInformationId";
    api.documentation.objectDescriptionPropertyNames = ["IndustryLibraryType"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerIndustryLibraryTypeInformation;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerIndustryLibraryTypeInformationModel();
    api.documentation.documentationUrlBase = "/report-compiler/industry-library-type-information/";
    api.documentation.securityAccessArea = Constants.AccessArea.IndustryLibraryTypeInformation;
    api.pathVariables = "{industryLibraryTypeInformationId}";
    api.pathModelProperties = "IndustryLibraryTypeInformationId";
    api.cacheName = "reportCompilerObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibraryTypeInformation}`, null, null, true);
    return api;
  };

  public static ReportCompilerIndustryLibraryApproval(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerIndustryLibraryApproval");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Industry Library Approval";
    api.documentation.objectPrimaryKey = "VisibilityId";
    api.documentation.objectDescriptionPropertyNames = ["AssetId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerIndustryLibraryApproval;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerIndustryLibraryApprovalViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/industry-library-approval/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryIndustryApproval;
    api.pathVariables = "{visibilityId}";
    api.pathModelProperties = "VisibilityId";
    api.cacheName = "reportCompilerObjectCache";
    api.cacheLevel = CacheLevel.Volatile;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibraryApproval}`);
    return api;
  };

  public static ReportCompilerIndustryLibraryApprovalRequest(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerIndustryLibraryApprovalRequest");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Industry Library Approval";
    api.documentation.objectPrimaryKey = "VisibilityId";
    api.documentation.objectDescriptionPropertyNames = ["AssetId"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ReportCompilerIndustryLibraryApproval;
    api.documentation.responseDataModelObject = new m5rc.ReportCompilerIndustryLibraryApprovalViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/industry-library-approval-request/";
    api.pathVariables = "{assetId}";
    api.pathModelProperties = "AssetId";
    api.cacheName = null;
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibraryApproval}/{assetId}/${m.RouteSegment.Request}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Request Industry Library Entry Approval";
    api.endpoints.slice(-1)[0].documentation.menuText = "Request Industry Library Entry Approval";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api submits a request for industry library entry approval.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerIndustryLibraryApprovalApprove(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerIndustryLibraryApprovalApprove");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Industry Library Approval";
    api.documentation.objectPrimaryKey = "VisibilityId";
    api.documentation.objectDescriptionPropertyNames = ["AssetId"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ReportCompilerIndustryLibraryApproval;
    api.documentation.responseDataModelObject = new m5rc.ReportCompilerIndustryLibraryApprovalViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/industry-library-approval-approve/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryIndustryApproval;
    api.pathVariables = "{assetId}";
    api.pathModelProperties = "AssetId";
    api.cacheName = null;
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibraryApproval}/{assetId}/${m.RouteSegment.Approve}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Approve Industry Library Entry";
    api.endpoints.slice(-1)[0].documentation.menuText = "Approve Industry Library Entry";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api submits an approval for industry library entry use.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerIndustryLibraryApprovalReject(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerIndustryLibraryApprovalReject");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Industry Library Approval";
    api.documentation.objectPrimaryKey = "VisibilityId";
    api.documentation.objectDescriptionPropertyNames = ["AssetId"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ReportCompilerIndustryLibraryApproval;
    api.documentation.responseDataModelObject = new m5rc.ReportCompilerIndustryLibraryApprovalViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/industry-library-approval-reject/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryIndustryApproval;
    api.pathVariables = "{assetId}";
    api.pathModelProperties = "AssetId";
    api.cacheName = null;
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.IndustryLibraryApproval}/{assetId}/${m.RouteSegment.Reject}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Reject Industry Library Entry";
    api.endpoints.slice(-1)[0].documentation.menuText = "Reject Industry Library Entry";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api submits a rejection for industry library entry use.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerTemplateTypeSelectionsReview(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerTemplateTypeSelectionsReview");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Selections";
    api.documentation.objectPrimaryKey = "";
    api.documentation.documentationUrlBase = "/report-compiler/template-types/review-document";
    api.pathVariables = ["{templateTypeId}"];
    api.pathModelProperties = ["TemplateTypeId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.TemplateTypes}/${m.RouteSegment.ReviewDocument}?templateTypeId={templateTypeId}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Template Type Selections Review Document";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Template Type Selections Review Document";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to download a selections review document.";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to download a selections review document.
<br/><br/>
It is the responsibility of the API caller to save the contents as a file.  This is not directly possible in javascript since there is no access to a user's file system from within
the browser.  One method of doing this is to append the authentication token as a token string parameter to the API url and assigning that to the
window.location.href property (e.g. \https://the.api.url/report-compiler/selections/review-document?templateType=null&token=eyJ0eXAiOiJKV1QNiJ9...\) which will then trigger
the browser's standard open/save behavior.  Since the use of an API Key in a browser session is not considered safe it is not supported as a query
string parameter.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "download";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "Download";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.TemplateTypes}/${m.RouteSegment.ReviewDocument}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Upload Template Type Selections Review Document";
    api.endpoints.slice(-1)[0].documentation.menuText = "Upload Template Type Selections Review Document";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to upload a selections review document so changes can be parsed and applied to the library as a multipart/form-data form submission.
<br/><br/>
The form-data should have a part named \Data\ which will be the JSON of the any desired data and a part named \file\ which will be the selections review document being uploaded.
The expected \Data\ is a single property DryRun which when true will result in proposed changes being reported back with no changes to be committed to the database.
<br/><br/>
For example:
<br/><br/>
-----FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\Data\
<br/>
{\DryRun\:true}
<br/><br/>
------FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\file\; filename=\selections-review.xlsx\
<br/>
Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;
`;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5core.PickListImportResult();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.ReportCompilerLibraryCoverage;
    return api;
  };

  public static ReportCompilerCase(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCase");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.objectDescriptionPropertyNames = ["Title", "ExternalCaseNumber", "CaseNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.requestAndResponseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = "{caseId}";
    api.pathModelProperties = "CaseId";
    api.cacheName = "caseObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}`, new m5.CaseListViewModel(), Constants.DataModelName.CaseList, true);
    return api;
  };

  public static ReportCompilerCaseTemplateOptions(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseTemplateOptions");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Template Options";
    api.documentation.objectPrimaryKey = "CaseOptionsId";
    api.documentation.objectDescriptionPropertyNames = ["CaseOptionsId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerCaseOptions;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerCaseOptionsModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-template-options/";
    api.documentation.securityAccessArea = Constants.AccessArea.CaseTemplate;
    api.pathVariables = ["{caseTemplateId}", "{caseOptionsId}"];
    api.pathModelProperties = ["OwnerResourceId", "CaseOptionsId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.CaseTemplates}/{caseTemplateId}/${m.RouteSegment.Options}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseOptions(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseOptions");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Options";
    api.documentation.objectPrimaryKey = "CaseOptionsId";
    api.documentation.objectDescriptionPropertyNames = ["CaseOptionsId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerCaseOptions;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerCaseOptionsModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-options/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{caseOptionsId}"];
    api.pathModelProperties = ["OwnerResourceId", "CaseOptionsId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Options}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseTemplateReportSections(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseTemplateReportSections");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Template Report Sections";
    api.documentation.objectPrimaryKey = "ReportSectionConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["SectionHeading"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportSection;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportSectionConfigurationModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-template-report-sections/";
    api.documentation.securityAccessArea = Constants.AccessArea.CaseTemplate;
    api.pathVariables = ["{caseTemplateId}", "{reportSectionConfigurationId}"];
    api.pathModelProperties = ["OwnerResourceId", "ReportSectionConfigurationId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.CaseTemplates}/{caseTemplateId}/${m.RouteSegment.Sections}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseReportSections(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReportSections");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report Sections";
    api.documentation.objectPrimaryKey = "ReportSectionConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["SectionHeading"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportSection;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportSectionConfigurationModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-report-sections/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{reportSectionConfigurationId}"];
    api.pathModelProperties = ["OwnerResourceId", "ReportSectionConfigurationId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Sections}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseReportSectionText(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReportSectionText");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report Section Text";
    api.documentation.objectPrimaryKey = "ReportSectionTextId";
    api.documentation.objectDescriptionPropertyNames = ["Title"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportSectionText;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportSectionTextModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-report-section-text/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{reportSectionTextId}"];
    api.pathModelProperties = ["CaseId", "ReportSectionTextId"];
    api.cacheName = null; //"reportCompilerCaseObjectCache";
    api.cacheLevel = null; //CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Sections}/${m.RouteSegment.Text}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseReportSectionTextSuggestion(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReportSectionTextSuggestion");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report Section Text Suggestion";
    api.documentation.objectPrimaryKey = "ReportSectionTextId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportSectionText;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportSectionTextModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-report-section-text-suggestion/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = "{caseId}";
    api.pathModelProperties = "CaseId";
    api.cacheName = null;
    api.cacheLevel = CacheLevel.ChangesOften;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Sections}/${m.RouteSegment.Text}/${m.RouteSegment.Suggestions}?reportSectionConfigurationId={reportSectionConfigurationId}&group={group}&category={category}&useLibrarySelectionCriteria={useLibrarySelectionCriteria}&saveToCase={saveToCase}&page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    return api;
  };

  public static ReportCompilerCaseReportSectionTextUpload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReportSectionTextUpload");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report Section Text Upload";
    api.documentation.objectPrimaryKey = "ReportSectionTextId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportSectionText;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportSectionTextModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-report-section-text-upload/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = "{caseId}";
    api.pathModelProperties = "CaseId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Sections}/${m.RouteSegment.Text}/${m.RouteSegment.Upload}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Report Section Text Upload";
    api.endpoints.slice(-1)[0].documentation.menuText = "Report Section Text Upload";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to upload local-only section text with an accompanying upload as a multipart/form-data form submission.
<br/><br/>
The form-data should have a part named \Data\ which will be the JSON of the model and a part named \file\ which will be the file being uploaded.
<br/><br/>
For example:
<br/><br/>
-----FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\Data\
<br/>
{\CaseId\:null,\ReportSectionConfigurationId\:null,....}
<br/><br/>
------FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\file\; filename=\text.docx\
<br/>
Content-Type: application/vnd.openxmlformats-officedocument.wordprocessingml.document;
`;
    return api;
  };

  public static ReportCompilerCaseTemplateReportAttachments(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseTemplateReportAttachments");
    api.version = version;
    api.documentation.objectDescription = "Case Template Report Attachments";
    api.documentation.objectPrimaryKey = "ReportAttachmentConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["AttachmentHeading"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportAttachment;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportAttachmentConfigurationModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-template-report-attachments/";
    api.documentation.securityAccessArea = Constants.AccessArea.CaseTemplate;
    api.pathVariables = ["{caseTemplateId}", "{reportAttachmentConfigurationId}"];
    api.pathModelProperties = ["OwnerResourceId", "ReportAttachmentConfigurationId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.CaseTemplates}/{caseTemplateId}/${m.RouteSegment.Attachments}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseReportAttachments(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReportAttachments");
    api.version = version;
    api.documentation.objectDescription = "Case Report Attachments";
    api.documentation.objectPrimaryKey = "ReportAttachmentConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["AttachmentHeading"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportAttachment;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportAttachmentConfigurationModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-report-attachments/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{reportAttachmentConfigurationId}"];
    api.pathModelProperties = ["OwnerResourceId", "ReportAttachmentConfigurationId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Attachments}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseReportAttachmentDocuments(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReportAttachmentDocuments");
    api.version = version;
    api.documentation.objectDescription = "Case Report Attachment Documents";
    api.documentation.objectPrimaryKey = "ReportAttachmentDocumentId";
    api.documentation.objectDescriptionPropertyNames = ["FriendlyName", "Title"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportAttachmentDocument;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportAttachmentDocumentModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-report-attachment-documents/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{reportAttachmentDocumentId}"];
    api.pathModelProperties = ["CaseId", "ReportAttachmentDocumentId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Attachments}/${m.RouteSegment.Documents}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseSubjectMatterOther(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseSubjectMatterOther");
    api.version = version;
    api.documentation.objectDescription = "Case Subject Matter Other";
    api.documentation.objectPrimaryKey = "SubjectMatterId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerSubjectMatterOther;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerSubjectMatterOtherModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-subject-matter-other/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{subjectMatterId}"];
    api.pathModelProperties = ["CaseId", "SubjectMatterId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.SubjectMatter}/${m.RouteSegment.Other}`, null, null, false);
    return api;
  };

  public static ReportCompilerCaseSubjectMatterStructural(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseSubjectMatterStructural");
    api.version = version;
    api.documentation.objectDescription = "Case Subject Matter Structural";
    api.documentation.objectPrimaryKey = "SubjectMatterPropertyId";
    api.documentation.objectDescriptionPropertyNames = ["PropertyType"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerSubjectMatterStructural;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerSubjectMatterStructuralPropertyModel();
    api.documentation.documentationUrlBase = "/report-compiler/case-subject-matter-structural/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{subjectMatterPropertyId}"];
    api.pathModelProperties = ["CaseId", "SubjectMatterPropertyId"];
    api.cacheName = "reportCompilerCaseObjectCache";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.SubjectMatter}/${m.RouteSegment.Structural}`, null, null, false);
    return api;
  };

  public static ReportCompilerReportCreateDraft(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportCreateDraft");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.responseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/report/draft/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = "{caseId}";
    api.pathModelProperties = "CaseId";
    api.cacheName = null;
    api.parentApi = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Reports}/${m.RouteSegment.Draft}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Create Draft Report";
    api.endpoints.slice(-1)[0].documentation.menuText = "Create Draft Report";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used create a draft report for the specified case.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerReportCreateFinal(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportCreateFinal");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.responseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/report/final/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = "{caseId}";
    api.pathModelProperties = "CaseId";
    api.cacheName = null;
    api.parentApi = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Reports}/${m.RouteSegment.Final}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Create Final Report";
    api.endpoints.slice(-1)[0].documentation.menuText = "Create Final Report";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used create a final report for the specified case.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerReportMarkAsFinal(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportMarkAsFinal");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.responseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/report/final/version/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{assetId}"];
    api.pathModelProperties = ["CaseId", "AssetId"];
    api.cacheName = null;
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Reports}/${m.RouteSegment.Version}/{assetId}/${m.RouteSegment.Final}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Mark Report as Final Version";
    api.endpoints.slice(-1)[0].documentation.menuText = "Mark Report as Final Version";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used mark a version of a report as the final version.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerReportMarkAsDraft(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportMarkAsDraft");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.responseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/report/draft/version/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{assetId}"];
    api.pathModelProperties = ["CaseId", "AssetId"];
    api.cacheName = null;
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Reports}/${m.RouteSegment.Version}/{assetId}/${m.RouteSegment.Draft}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Mark Report as Draft Version";
    api.endpoints.slice(-1)[0].documentation.menuText = "Mark Report as Draft Version";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used mark a version of a report as a draft version.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerCaseReviewSubmit(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReviewSubmit");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.responseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/report/review/version/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{assetId}", "{reviewContactId}"];
    api.pathModelProperties = ["CaseId", "AssetId", "ReviewContactId"];
    api.cacheName = null;
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Reports}/${m.RouteSegment.Version}/{assetId}/${m.RouteSegment.ReviewSubmit}/{reviewContactId}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Submit Report for Review";
    api.endpoints.slice(-1)[0].documentation.menuText = "Submit Report for Review";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used submit a version of a report for review.  The reviewContactId property is optional and may be ignored depending on template configuration.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerCaseReviewRetract(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReviewRetract");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.responseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/report/review/retract/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{assetId}"];
    api.pathModelProperties = ["CaseId", "AssetId"];
    api.cacheName = null;
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Reports}/${m.RouteSegment.Version}/{assetId}/${m.RouteSegment.ReviewRetract}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Retract Report Review";
    api.endpoints.slice(-1)[0].documentation.menuText = "Retract Report Review";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used retract a version of a report from the review process.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerCaseReviewResult(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerCaseReviewResult");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Case Report";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.responseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/case/report/review/result/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{assetId}"];
    api.pathModelProperties = ["CaseId", "AssetId"];
    api.cacheName = null;
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Reports}/${m.RouteSegment.Version}/{assetId}/${m.RouteSegment.ReviewResult}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Mark Report as Review Result";
    api.endpoints.slice(-1)[0].documentation.menuText = "Mark Report as Review Result";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used mark a version of a report as the review results.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static ReportCompilerLibraryStats(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerLibraryStats");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Library Stats";
    api.documentation.objectPrimaryKey = "";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ReportCompilerLibraryStats;
    api.documentation.responseDataModelObject = new m5rc.ReportCompilerLibraryStatsModel();
    api.documentation.documentationUrlBase = "/report-compiler/library/stats/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryInsights;
    api.pathVariables = "";
    api.pathModelProperties = "";
    api.cacheName = "rcLibraryObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.Stats}`, ApiOperationType.List));
    return api;
  };

  public static ReportCompilerLibraryCoverage(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerLibraryCoverage");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescriptionPlural = "Library Coverage";
    api.documentation.objectPrimaryKey = "";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ReportCompilerLibraryCoverage;
    api.documentation.responseDataModelObject = new m5rc.ReportCompilerLibraryCoverageSummaryModel();
    api.documentation.documentationUrlBase = "/report-compiler/library/coverage/";
    api.documentation.securityAccessArea = Constants.AccessArea.LibraryInsights;
    api.pathVariables = ["{noCoverageOnly}"];
    api.pathModelProperties = ["noCoverageOnly"];
    api.cacheName = "rcLibraryObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.Coverage}?noCoverageOnly={noCoverageOnly}`, ApiOperationType.List));
    return api;
  };

  public static ReportCompilerLibrarySuggestionForOrganization(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerLibrarySuggestionForOrganization");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescriptionPlural = "Library Suggestion for Organization";
    api.documentation.objectPrimaryKey = "";
    api.documentation.responseDataModelDocumentationName = "";
    api.documentation.responseDataModelObject = null;
    api.documentation.documentationUrlBase = "/report-compiler/suggestion/organization/";
    api.pathVariables = ["{id}"];
    api.pathModelProperties = ["id"];
    api.cacheName = null
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.Suggestions}/${m.RouteSegment.Organization}/{id}`, ApiOperationType.Add));
    return api;
  };

  public static ReportCompilerLibraryReview(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerLibraryReview");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Library";
    api.documentation.objectPrimaryKey = "AssetId";
    //api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ReportCompilerFileSuggestion;
    //api.documentation.responseDataModelObject = new m5.rcAssetTextSelectionListViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/library/review-document";
    api.pathVariables = ["{libraryGroupId}", "{libraryCategories}", "{authorContactIds}", "{newPageForEachLibraryEntry}"];
    api.pathModelProperties = ["LibraryGroupId", "LibraryCategories", "AuthorContactIds", "NewPageForEachLibraryEntry"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReviewDocument}?libraryGroupId={libraryGroupId}&libraryCategories={libraryCategories}&authorContactIds={authorContactIds}&newPageForEachLibraryEntry={newPageForEachLibraryEntry}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Library Review Document";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Library Review Document";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to download a library review document.";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to download a library review document.
<br/><br/>
It is the responsibility of the API caller to save the contents as a file.  This is not directly possible in javascript since there is no access to a user's file system from within
the browser.  One method of doing this is to append the authentication token as a token string parameter to the API url and assigning that to the
window.location.href property (e.g. \https://the.api.url/report-compiler/library/review-document?libraryGroup=null&libraryCategory=null&contactId=null&token=eyJ0eXAiOiJKV1QNiJ9...\) which will then trigger
the browser's standard open/save behavior.  Since the use of an API Key in a browser session is not considered safe it is not supported as a query
string parameter.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "download";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "Download";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReviewDocument}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Upload Library Review Document";
    api.endpoints.slice(-1)[0].documentation.menuText = "Upload Library Review Document";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to upload a library review document so changes can be parsed and applied to the library as a multipart/form-data form submission.
<br/><br/>
The form-data should have a part named \Data\ which will be the JSON of the any desired data and a part named \file\ which will be the library review document being uploaded.
<br/><br/>
For example:
<br/><br/>
-----FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\Data\
<br/>
{\AssetId\:null,\ParentAssetId\:null,\ParentAssetTitle\:\\,\Title\:\\,....}
<br/><br/>
------FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\file\; filename=\library-review.docx\
<br/>
Content-Type: application/vnd.openxmlformats-officedocument.wordprocessingml.document;
`;
    return api;
  };

  public static ReportCompilerLibraryReviewRequest(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerLibraryReviewRequest");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Library";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.documentationUrlBase = "/report-compiler/library/review-document/request";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReviewDocument}/${m.RouteSegment.Request}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = new m5.QueryExecutionRequestOptionsViewModel();
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = Constants.DataModelName.QueryExecutionRequestOptions;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = [];
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.title = "Request Library Review Document";
    api.endpoints.slice(-1)[0].documentation.menuText = "Request Library Review Document";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to request that a library review document be created.  The results can be accessed using the query events api endpoints.`;
    return api;
  };

  public static ReportCompilerReportParserUpload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportParserUpload");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Library Report Parser Upload";
    api.documentation.objectPrimaryKey = "ReportId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportParserResultModel;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportParserResultModel();
    api.documentation.documentationUrlBase = "/report-compiler/library/report-parser/upload";
    api.documentation.securityAccessArea = Constants.AccessArea.ReportParser;
    api.pathVariables = "{reportId}";
    api.pathModelProperties = "ReportId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/${m.RouteSegment.Upload}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Report Parser Upload";
    api.endpoints.slice(-1)[0].documentation.menuText = "Report Parser Upload";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to create a report parser instance with an accompanying upload as a multipart/form-data form submission.
<br/><br/>
The form-data should have a part named \Data\ which will be the JSON of the model and a part named \file\ which will be the file being uploaded.
<br/><br/>
For example:
<br/><br/>
-----FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\Data\
<br/>
{\AssetId\:null,\ParentAssetId\:null,\ParentAssetTitle\:\\,\Title\:\\,....}
<br/><br/>
------FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\file\; filename=\report.docx\
<br/>
Content-Type: application/vnd.openxmlformats-officedocument.wordprocessingml.document;
`;
    return api;
  };

  public static ReportCompilerReportParser(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportParser");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Report Parser";
    api.documentation.objectPrimaryKey = "ReportId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportParserResultModel;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportParserResultModel();
    api.documentation.documentationUrlBase = "/report-compiler/library/report-parser";
    api.documentation.securityAccessArea = Constants.AccessArea.ReportParser;
    api.pathVariables = "{reportId}";
    api.pathModelProperties = "ReportId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/{reportId}`, ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/{reportId}`, ApiOperationType.Edit));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/{reportId}`, ApiOperationType.Delete));
    // Add is customized since it's multipart form data not plain json.
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/${m.RouteSegment.Upload}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Report Parser Upload";
    api.endpoints.slice(-1)[0].documentation.menuText = "Report Parser Upload";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to create a report parser instance with an accompanying upload as a multipart/form-data form submission.
<br/><br/>
The form-data should have a part named \Data\ which will be the JSON of the model and a part named \file\ which will be the file being uploaded.
<br/><br/>
For example:
<br/><br/>
-----FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\Data\
<br/>
{\AssetId\:null,\ParentAssetId\:null,\ParentAssetTitle\:\\,\Title\:\\,....}
<br/><br/>
------FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\file\; filename=\report.docx\
<br/>
Content-Type: application/vnd.openxmlformats-officedocument.wordprocessingml.document;
`;
    return api;
  };



  public static ReportCompilerReportParserText(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportParserText");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Report Parser Text";
    api.documentation.objectPrimaryKey = "ReportId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportParserResultAssetModel;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportParserResultAssetModel();
    api.documentation.documentationUrlBase = "/report-compiler/library/report-parser/text";
    api.documentation.securityAccessArea = Constants.AccessArea.ReportParser;
    api.pathVariables = ["{reportId}", "{paragraphNumber}", "{paragraphType}"];
    api.pathModelProperties = ["ReportId", "ParagraphNumber", "ParagraphType"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/{reportId}/${m.RouteSegment.Text}/{paragraphType}?page={page}&size={size}`, ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/{reportId}/${m.RouteSegment.Text}/{paragraphNumber}`, ApiOperationType.Edit));
    return api;
  };


  public static ReportCompilerReportParserTextMetaData(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportCompilerReportParserTextMetaData");
    api.brands = ["ReportCompiler"];
    api.version = version;
    api.documentation.objectDescription = "Report Parser Text Meta Data";
    api.documentation.objectPrimaryKey = "ReportId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ReportCompilerReportParserParagraphMetaDataEditViewModel;
    api.documentation.requestAndResponseDataModelObject = new m5rc.ReportCompilerReportParserParagraphMetaDataEditViewModel();
    api.documentation.documentationUrlBase = "/report-compiler/library/report-parser/text/meta-data";
    api.documentation.securityAccessArea = Constants.AccessArea.ReportParser;
    api.pathVariables = "{reportId}";
    api.pathModelProperties = "ReportId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.ReportCompiler}/${m.RouteSegment.Library}/${m.RouteSegment.ReportParser}/{reportId}/${m.RouteSegment.Text}/${m.RouteSegment.MetaData}`, ApiOperationType.Edit));
    return api;
  };



  //#endregion

}
