import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputInformationValues } from 'projects/common-lib/src/lib/input/input-base-component';
import { InputContactPickerModalComponent } from '../input-contact-picker-modal/input-contact-picker-modal.component';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { UxService } from '../../services/ux.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'ib-input-contact',
  templateUrl: './input-contact.component.html',
  styleUrls: ['./input-contact.component.css']
})
export class InputContactComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() name: string = "";
  @Input() label: string = "";
  /**
  Prefix rendered directly before the label.  Can be used for things like * asterisk prefix
  to indicate a required field or other custom prefix indicators.  The prefix is not
  translated and provides the ability for the label that is translated to share translations
  that otherwise would have to be repeated due to the prefix.  For example, "Customer"
  translation can be reused where "*Customer:" label would otherwise need a repeated translation.
  */
  @Input() public labelPrefix: string = "";
  /**
  Suffix rendered directly after the label.  Can be used for things like : colon suffix
  prompt before input or other custom suffix indicators.  The suffix is not
  translated and provides the ability for the label that is translated to share translations
  that otherwise would have to be repeated due to the suffix.  For example, "Customer"
  translation can be reused where "*Customer:" label would otherwise need a repeated translation.
  */
  @Input() public labelSuffix: string = "";
  @Input() placeholder: string = "";
  @Input() tooltip: string = "";
  @Input() bold: boolean = false; // true = bold label
  @Input() tight: boolean = false; // true = tight form groups
  @Input() vertical: boolean = false; // true = vertical form with label above input control.  default is false.
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() standalone: boolean = false; // true will not mark form dirty when control is dirty
  @Input() size: string = ""; // "",large,small
  @Input() width: string = "";
  @Input() filter: string = "";
  @Input() contactTypes: string[] = [Constants.ContactType.Directory, Constants.ContactType.Customer];
  @Input() data: m5.ContactReferenceViewModel = null;

  @Input() includeRequiredIcon: boolean = true;

  @Input() allowRedirect: boolean = true;

  @Input() outerClass: string = "";
  @Input() labelClass: string = "";
  @Input() controlClass: string = "";

  @Input() showAddressType: boolean = false;
  @Input() parentContactId: number = null;
  @Input() parentContactName: string = "";
  @Input() parentContactType: string = "";
  @Input() addressTypeFilter: string | string[] = null;
  @Input() addressTypeCaption: string = "Address Type";
  @Input() allowClearSelection: boolean = true;
  @Input() allowAddContact: boolean = true;

  @Input() readOnlyErrorTitle: string = "";
  @Input() readOnlyErrorMessage: string = "";

  @Output() change: EventEmitter<EventModel> = new EventEmitter();
  @Output() status: EventEmitter<EventModel> = new EventEmitter();

  @Input() public errorRequiredMessage: string = "This value is required.";
  @Input() public errorMinimumLengthMessage: string = "This value must be at least {{MinimumLength}} characters long.";
  @Input() public errorMaximumLengthMessage: string = "This value cannot be longer than {{MaximumLength}} characters but is currently {{ActualLength}} characters long.";
  @Input() public errorInvalidFormatMessage: string = "This is not a valid format: {{FormatErrorMessage}}";
  @Input() public errorOtherMessage: string = "This value is not valid: {{OtherErrorMessage}}";
  errorMessages: string[] = [];
  inputInformationValues: InputInformationValues = {};

  addressTypeDescription: string = "";

  /**
  Copy of attribute binding data in case user hits cancel on modal we don't want to have modified the data object submitted.
  */
  public contact: m5.ContactReferenceViewModel;


  constructor(
    protected contactService: ContactService,
    protected appService: AppService,
    protected uxService: UxService,
    protected ngbModalService: NgbModal) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.configure();
    if (changes.data && this.data) {
      // Work with a cloned object
      this.contact = Helper.deepCopy(this.data);
      // If we have an id but don't have a contact type let's figure it out
      if (this.data.ContactId && !this.data.ContactType) {
        this.contactService.getContactListObject(this.data.ContactId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((contact) => {
          if (contact) {
            this.data.ContactType = contact.ContactType;
            this.contact.ContactType = contact.ContactType;
          }
        });
      }
    }
    if (changes.addressTypeFilter && this.addressTypeFilter) {
      // If we have an address type filter then find a description to go with it.
      if (this.addressTypeFilter) {
        const addressType = this.getAddressType();
        this.addressTypeDescription = addressType;
        this.appService.getPickListText(addressType, Constants.PickList.ContactAddressType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(description => {
          if (description) {
            this.addressTypeDescription = description;
          }
        });
      }
    }
  }

  configure() {
    if (this.label && !this.placeholder) {
      this.placeholder = this.label;
    }
  }

  openContactPicker($event) {
    if (this.readonly) {
      if (this.readOnlyErrorMessage) {
        this.uxService.modal.alertInfo(Helper.getFirstDefinedString(this.readOnlyErrorTitle, "Cannot Change"), this.readOnlyErrorMessage);
      }
      return;
    }
    if (this.contactTypes.length === 1 && this.contactTypes[0] === Constants.ContactType.Contact && !this.parentContactId) {
      this.uxService.modal.alertDanger("Error", `You cannot select a ${this.addressTypeDescription} without first selecting a ${Helper.getFirstDefinedString(this.contactService.getContactTypeDescription(this.parentContactType), "Owner")}.`);
      return;
    }
    //console.error("contact picker button", $event);
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.title = "Select Contact"
    if (this.contactTypes && this.contactTypes.length === 1) {
      options.title = `Select ${this.contactService.getContactTypeDescription(this.contactTypes[0])}`;
    }
    // Open the modal
    const modalRef = this.ngbModalService.open(InputContactPickerModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.data = Helper.deepCopy(this.contact);
    modalRef.componentInstance.contactTypes = this.contactTypes;
    modalRef.componentInstance.showAddressType = this.showAddressType;
    modalRef.componentInstance.parentContactId = this.parentContactId;
    modalRef.componentInstance.parentContactName = this.parentContactName;
    modalRef.componentInstance.addressTypeFilter = this.addressTypeFilter;
    modalRef.componentInstance.addressTypeCaption = this.addressTypeCaption;
    modalRef.componentInstance.allowClearSelection = (this.allowClearSelection && !this.required);
    modalRef.componentInstance.allowAddContact = this.allowAddContact;

    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModel) => {
      // User hit ok so value.data is the data object.  If in add mode then add to data store.  If in edit mode then update data store.
      this.contact = value.data;
      this.data.ContactId = value.data.ContactId;
      this.data.ContactName = value.data.ContactName;
      this.data.ContactType = value.data.ContactType;
      this.data.ContactEmail = value.data.ContactEmail;
      const payload: EventModel = new EventModel("change", event, value.data, new EventElementModel("contact-picker", null, this.label, this.label, this.placeholder));
      this.change.emit(payload);
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }



  openContact($event) {
    //console.error("open contact", $event);
    this.contactService.goToContact(this.data.ContactType, this.data.ContactId, this.data.ContactName, "edit");
  }


  protected getAddressType(): string {
    let addressType = "";
    if (Helper.isArray(this.addressTypeFilter)) {
      if ((this.addressTypeFilter as string[]).length > 0) {
        addressType = (this.addressTypeFilter as string[])[0];
      }
    } else {
      addressType = this.addressTypeFilter as string;
    }
    return addressType;
  }

}
