import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputSelectButtonComponent),
  multi: true
};

@Component({
  selector: 'ib-input-select-button',
  templateUrl: './input-select-button.component.html',
  styleUrls: ['./input-select-button.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputSelectButtonComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public color: string = "";

  @Input() public allowSearch: boolean = false;

  @Input() public buttonClasses: string = "";

  @Input() public buttonStyles: string = "";

  @Input() public buttonLabelType: "Value" | "DisplayText" | "Template" = "DisplayText";

  @Input() public buttonLabelTemplate: string = "";

  @Input() public buttonLabelMaxLength: number = 100;


  searchText: string = "";

  displayText: string = "";

  public buttonColor: string = "outline-dark";
  public classes: string = "btn btn-outline-dark dropdown-toggle"; // me-2


  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
    this.plainInput = true;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.configure();
    this.findDisplayText(this.value);
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    // Use our color input to init our color button
    if (this.color) {
      //console.error(this.value);
      this.buttonColor = this.color;
    } else {
      //console.error("no color");
      // We were not given a color and none isn't an option
      this.color = "info";
      this.buttonColor = "info";
    }

    // Now build our button classes
    this.buildButtonClasses();

  }

  protected buildButtonClasses() {
    this.classes = `btn btn-${this.buttonColor}`;
    if (Helper.equals(this.size, "large", true) || Helper.equals(this.size, "lg", true)) {
      this.classes += " btn-lg";
    } else if (Helper.equals(this.size, "small", true) || Helper.equals(this.size, "sm", true)) {
      this.classes += " btn-sm";
    } else if (this.size) {
      Log.errorMessage(`Context color picker button size ${this.size} is not valid.  Possible values include: "", "large", "small".  Defaulting to "".`);
    }
    this.classes += " dropdown-toggle"; // me-2
  }


  writeValue(value: any) {
    super.writeValue(value);
    // Find display text for selected item
    this.findDisplayText(value);
  }

  protected onPickListReady() {
    super.onPickListReady();
    this.findDisplayText(this.value);
  }


  findDisplayText(value: string) {
    //console.error(`find display text for ${value}`);
    if (value) {
      const matches = this.pickList.filter(x => Helper.equals(x.Value, value, true));
      //console.error(matches);
      if (matches.length > 0) {
        this.setButtonLabelDisplayText(matches[0]);
      } else {
        this.displayText = value;
      }
    } else if (this.optionsIncludeNone) {
      this.displayText = this.optionsNoneLabel;
      this.value = "";
    } else {
      this.displayText = value;
    }
  }


  public selectValue(option: m5core.PickListSelectionViewModel, event: any) {
    //console.error("selected", option);
    if (this.optionsIncludeNone && Helper.equals(option.DisplayText, this.optionsNoneLabel, true)) {
      this.displayText = option.DisplayText;
      this.value = "";
    } else {
      this.value = option.Value;
      this.setButtonLabelDisplayText(option);
    }
    this.fireChange(event, null);
  }


  protected setButtonLabelDisplayText(option: m5core.PickListSelectionViewModel) {
    if (this.buttonLabelType === "DisplayText") {
      this.displayText = option.DisplayText || option.Value;
    } else if (this.buttonLabelType === "Value") {
      this.displayText = option.Value || option.DisplayText;
    } else if (this.buttonLabelType === "Template" && this.buttonLabelTemplate) {
      this.displayText = Helper.stringFormat(this.buttonLabelTemplate, option, false, false);
    } else {
      this.displayText = option.DisplayText || option.Value;
    }
  }


}
