import { Injectable } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { MenuItem } from 'primeng/api';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { RecentlyUsedList } from 'projects/core-lib/src/lib/helpers/recently-used';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { MenuService } from 'projects/core-lib/src/lib/services/menu.service';
import { BaseService } from 'projects/core-lib/src/lib/services/base.service';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from 'projects/core-lib/src/lib/services/app-status.service';
import { AlertItem, AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { ButtonItem, ButtonRole } from '../../ux-models';

@Injectable({
  providedIn: 'root'
})
export class NavService extends BaseService {

  public canSideCollapse: boolean = true;
  public isSideCollapsed: boolean = false;

  public theme: string = "dark";
  public themeClasses: string = "ib-nav-dark";

  protected versionToShowInFooter: string = "";
  public footers: string[] = [];

  public get showPageHistory(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    return true;
  }

  public get pageHistory(): RecentlyUsedList {
    return this.appService.pageHistory;
  }

  public get showRecentCases(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    if (!this.appService.isUserDirectoryUser) {
      return false;
    }
    if (AppConfig.hideRecentCases) {
      return false;
    }
    return true;
  }

  public get recentCases(): RecentlyUsedList {
    return this.appService.recentCases;
  }

  public get showRecentCustomers(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    if (!this.appService.isUserDirectoryUser) {
      return false;
    }
    if (AppConfig.hideRecentCustomers) {
      return false;
    }
    return true;
  }

  public get recentCustomers(): RecentlyUsedList {
    return this.appService.recentCustomers;
  }

  public get showProfileOption(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    return true;
  }

  public get showMyAppSettingsOption(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    return true;
  }

  public get showTasksOption(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    return true;
  }

  public get showBookmarkOption(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    if (!this.appService.isUserDirectoryUser) {
      return false;
    }
    if (AppConfig.hideBookmarkOption) {
      return false;
    }
    return true;
  }

  public get showSetHomePageOption(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    if (!this.appService.isUserDirectoryUser) {
      return false;
    }
    return this.appService.settings.PortalHomePageCanBeCustomized;
  }

  public get showSiteMapOption(): boolean {
    if (this.appService.config.type !== "app") {
      return false;
    }
    if (!this.appService.isUserDirectoryUser) {
      return false;
    }
    return true;
  }

  public get showStandardSiteElements(): boolean {
    if (this.appService.config && this.appService.config.hideStandardSiteElements) {
      return false;
    }
    // Use our route and app service to sort out if we are hiding
    // standard site elements for this url.  Routes like login,
    // asset file viewers, etc. are designed to not have those
    // elements visible.
    // Below use of router doesn't give us the performance we want
    if (this.appService.hideStandardSiteElements(window?.location?.pathname)) {
      return false;
    }
    // Check router url
    const url = this.router.url;
    const hide = this.appService.hideStandardSiteElements(url);
    //console.error(`site element display check: url = ${url}; hide = ${hide}`);
    return !hide;
  }

  public get isLoggedIn(): boolean {
    return this.appService.isLoggedIn();
  }


  protected appIsOffline: boolean = false;
  protected appIsWaitingForReload: boolean = false;


  constructor(
    protected appService: AppService,
    protected menuService: MenuService,
    protected router: Router) {

    super();

    // Set up the theme
    this.setupTheme();

    // Set up the footer
    this.setupFooter();

    // Monitor app info changes since that may impact information we render in the footer
    this.appService.appInfoFeed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((appInfo: m5core.ApplicationInformationModel) => {
      this.setupFooter();
    });

    // Monitor the app state to know if we're offline, have a new version to reload, etc.
    this.appService.status.monitor().pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: AppState) => {
      this.updateAppState(state);
    });


  }

  public updateAppState(state: AppState) {

    if (this.appService.status.currentState && !Helper.equals(this.versionToShowInFooter, this.appService.status.currentState.versionRunning, true)) {
      this.versionToShowInFooter = this.appService.status.currentState.versionRunning;
      this.setupFooter();
    }

    //console.error("nav service app state is", state);
    const internetAccessId: string = "AppInternetAccessStatusAlert";
    if (this.appIsOffline && state.hasInternetAccess) {
      this.appIsOffline = false;
      this.appService.alertManager.close(internetAccessId);
    } else if (!this.appIsOffline && !state.hasInternetAccess) {
      this.appIsOffline = true;
      const alert: AlertItem = new AlertItem();
      alert.id = internetAccessId;
      alert.type = AlertItemType.Danger;
      alert.message = "<i class='fas fa-wifi-slash'></i>&nbsp;&nbsp;<strong>You are offline.</strong>  Please reconnect to continue using the application.";
      // Someday when we can run offline with PWA maybe change message to this; "Some functionality may be unavailable.";
      alert.canClose = false;
      this.appService.alertManager.add(alert);
    }

    const reloadApplicationId: string = "AppReloadRequiredAlert";
    if (this.appIsWaitingForReload && !state.needsReload) {
      this.appIsWaitingForReload = false;
      this.appService.alertManager.close(reloadApplicationId);
    } else if (!this.appIsWaitingForReload && state.needsReload) {
      this.appIsWaitingForReload = true;
      const alert: AlertItem = new AlertItem();
      alert.id = reloadApplicationId;
      alert.type = AlertItemType.Danger;
      alert.message = "<p class='mb-2'><strong>A new version of the application is available.</strong>  Please save any pending work and then reload the application now.</p>";
      alert.canClose = false;
      alert.buttons.push(new ButtonItem("Reload", "redo", "danger", () => {
        window.location.reload();
      }, ButtonRole.Undefined));
      alert.buttons.slice(-1)[0].size = "sm";
      this.appService.alertManager.add(alert);
    }

  }

  public setupTheme() {
    if (AppConfig && AppConfig.theme) {
      this.theme = AppConfig.theme;
    }
    if (Helper.equals(this.theme, "dark", true)) {
      this.themeClasses = "ib-nav-dark";
    } else if (Helper.equals(this.theme, "light", true)) {
      this.themeClasses = "ib-nav-light";
    } else { // default
      this.themeClasses = "ib-nav-dark";
    }
  }



  public setupFooter() {
    this.footers = [];
    if (this.appService.appInfoOrDefault.PartitionDescription || this.appService.appInfoOrDefault.PartitionId) {
      this.footers.push(`${this.appService.appInfoOrDefault.PartitionDescription} (${this.appService.appInfoOrDefault.PartitionId})`);
    }
    const copyright = this.appService.getCopyrightHtml();
    if (copyright) {
      this.footers.push(copyright);
    }
    const patent = this.appService.getPatentHtml();
    if (patent) {
      this.footers.push(patent);
    }
    const poweredBy = this.appService.getPoweredByHtml();
    if (poweredBy) {
      this.footers.push(poweredBy);
    }
    if (!this.versionToShowInFooter && this.appService.status.currentState) {
      this.versionToShowInFooter = this.appService.status.currentState.versionRunning;
    }
    if (this.versionToShowInFooter) {
      this.footers.push(`v${this.versionToShowInFooter}`);
    }
  }


  public isMobile() {
    return Helper.isMobile();
  }

}
