import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'defaultTextWhenEmpty'
})
export class DefaultTextWhenEmptyPipe implements PipeTransform {

  public transform(value: string, alternates: string[] | string): string {
    if (Helper.isString(alternates)) {
      return Helper.getFirstDefinedString(value, alternates as string);
    } else {
      return Helper.getFirstDefinedString(value, ...(alternates as string[]));
    }
  }

  //transform(value: any, args?: any): any {
  //  return null;
  //}

}
